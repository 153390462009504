import { gql } from '@apollo/client'

export const CORE_LANDING_PAGE_FIELDS = gql`
    fragment CoreLandingPageFields on LandingPage {
        id
        domain
        title
        email
        status
    }
`
export const LANDING_PAGE_FIELDS = gql`
    ${CORE_LANDING_PAGE_FIELDS}
    fragment LandingPageFields on LandingPage {
        ...CoreLandingPageFields
        formFields
        metaKeywords
        metaDescription
        customHtmlHeader
        hasForm
        formButtonSettings
        pageSettings
        pagePopupSettings
        formContent
        logo
        logoImage
        background
        backgroundImage
        backgroundVideo
        termsAndConditions
        theme {
            id
        }
    }
`

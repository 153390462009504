import React, { Fragment, useContext, useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import { AuthContext } from '../../context/auth-context'
import Loader from '../../layouts/components/Loader'

const GET_INSIGHTS = gql`
    query Publishers($where: PublisherCampaignsConnectionWhere, $publishersWhere2: PublisherWhere) {
        publishers(where: $publishersWhere2) {
            name
            campaignsConnection(where: $where) {
                edges {
                    conversionPerAds
                }
            }
        }
    }`

const GET_LANDING_PAGES = gql`
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            id
            title
            campaign {
                name
            }
        }
    }`

const LiveCampaignPerformanceChart = ({ name }) => {
    const { user } = useContext(AuthContext)
    const [conversionRates, setConversionRates] = useState({})
    const [labels, setLabels] = useState([])

    const insights = useQuery(GET_INSIGHTS)
    const landingPages = useQuery(GET_LANDING_PAGES)

    useEffect(() => {
        if (insights.data?.publishers.length > 0 && landingPages.data?.landingPages.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges
            const creatives = landingPages.data.landingPages

            const rates = data.map((campaign) => JSON.parse(campaign.conversionPerAds))
            const flattenedObject = rates.reduce((acc, obj) => {
                return { ...acc, ...obj }
            }, {})
            const keyValuePairs = Object.entries(flattenedObject)

            keyValuePairs.sort((a, b) => b[1] - a[1])
            const top5Pairs = keyValuePairs.slice(0, 5)
            const top5Object = Object.fromEntries(top5Pairs)

            const labels = Object.keys(top5Object).map((item) => {
                const creative = creatives.find((creative) => creative.id === item)
                return creative.campaign.name + ' - ' + creative.title
            })

            setConversionRates(top5Object)
            setLabels(labels)
        }
    }, [insights.data, landingPages.data])

    const options = {
        chart: {
            type: 'bar',
            height: 350,
            toolbar: {
                show: false
            }
        },
        colors: ['#427EEB'],
        plotOptions: {
            bar: {
                borderRadius: 4,
                horizontal: true,
            }
        },
        dataLabels: {
            enabled: false
        },
        xaxis: {
            categories: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
                ? ['Landing Page 1', 'Landing Page 2', 'Landing Page 3', 'Landing Page 4', 'Landing Page 5']
                : labels
        },
        grid: {
            show: false
        },
    }

    const series = [{
        name: 'Conversion Rate (%)',
        data: process.env.REACT_APP_DEMO_USER_ID.includes(user.id)
            ? [75, 85, 78, 72, 84]
            : Object.values(conversionRates).map((item) => item.toFixed(1))
    }]

    return (
        <Fragment>
            { insights.loading || landingPages.loading ? (
                <Loader fullScreen={false} />
            ) : (
                <Col md={12} xl={4}>
                    <Card data-aos="fade-up" data-aos-delay="1000">
                        <div className="flex-wrap card-header d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">{name}</h4>
                            </div>
                        </div>
                        <Card.Body>
                            <Chart className="d-activity" options={options} series={series} type="bar" height="300" />
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Fragment>
    )
}

export default LiveCampaignPerformanceChart
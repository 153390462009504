import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { Row, Col, Alert, Spinner, Button } from 'react-bootstrap'
import { SettingsContext } from '../context/settings-context'
import Card from '../components/common/Card'
import LogoDark from '../assets/images/logo-text-dark.png'
import LogoLight from '../assets/images/logo-text-light.png'

const CONFIRM_EMAIL = gql`
    mutation ConfirmEmail($confirmEmailInput: ConfirmEmailInput!) {
        confirmEmail(confirmEmailInput: $confirmEmailInput)
    }
`

const ConfirmEmail = () => {
    const { theme } = useContext(SettingsContext)

    const [message, setMessage] = useState('')
    const [errors, setError] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const [confirmEmail, { loading }] = useMutation(CONFIRM_EMAIL, {
        onCompleted: (data) => {
            setMessage('Email confirmed successfully. You can now log in.')
            setTimeout(() => navigate('/login'), 3000)
        },
        onError: ({ graphQLErrors }) => {
            setError(graphQLErrors)
        },
    })

    useEffect(() => {
        const params = new URLSearchParams(location.search)
        const code = params.get('code')
        const email = params.get('email')

        if (code && email) {
            confirmEmail({ variables: { confirmEmailInput: { code, email } } })
        } else {
            setMessage('Invalid confirmation link.')
        }
    }, [confirmEmail, location.search])

    return (
        <Fragment>
            <section className="iq-auth-page">
                <Row className="d-flex align-items-center justify-content-center vh-100 w-100">
                    <Col md="4" xl="4">
                        <Card className="p-4">
                            <Card.Body>
                                <div className="text-center" style={{ marginBottom: '30px' }}>
                                    <img
                                        src={theme === 'dark' ? LogoDark : LogoLight}
                                        alt="Turba Media"
                                        style={{ height: '45px' }}
                                    />
                                </div>
                                <h3 className="text-center">Confirm Email</h3>
                                <p className="text-center">Confirm your Turba account email</p>
                                {message && <Alert variant="success">{message}</Alert>}
                                {errors.map((error, key) => (
                                    <Alert
                                        key={key}
                                        variant="warning rounded-0 alert-dismissible fade show"
                                        role="alert"
                                    >
                                        <span> {error.message} </span>
                                    </Alert>
                                ))}
                                {loading && (
                                    <div className="text-center pb-3">
                                        <Spinner animation="grow" role="status" aria-hidden="true" />
                                    </div>
                                )}
                                <div className="text-center pb-3">
                                    <Button type="button" variant="btn btn-primary" onClick={() => navigate('/login')}>
                                        Go to Login
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default ConfirmEmail

import { Navigate } from 'react-router-dom'
import Login from '../pages/Login'
import SignUp from '../pages/SignUp'
import ConfirmEmail from '../pages/ConfirmEmail'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'

export const UnauthenticatedUserRoutes = [
    {
        path: 'login',
        element: <Login />,
    },
    {
        path: 'signup',
        element: <SignUp />,
    },
    {
        path: 'confirm-email',
        element: <ConfirmEmail />,
    },
    {
        path: 'forgot-password',
        element: <ForgotPassword />,
    },
    {
        path: 'reset-password',
        element: <ResetPassword />,
    },
    {
        path: '*',
        element: <Navigate to="login" replace />,
    },
]

import Dashboard from '../pages/Dashboard'
import PageNotFound from '../pages/errors/PageNotFound'
import AdminProfile from '../pages/users/AdminProfile'
import AddCampaign from '../pages/campaigns/AddCampaign'
import CampaignList from '../pages/campaigns/CampaignList'
import EditCampaign from '../pages/campaigns/EditCampaign'
import AppLayout from '../layouts/AppLayout'
import { DocumentsRoutes } from './documents-routes'
import { PlatformsRoutes } from './platforms-routes'
import { LandingPageRoutes } from './landing-page-routes'
import { MediaBuyingRoutes } from './media-buying-routes'
import { CallbacksRoutes } from './callbacks-routes'

export const MainRoutes =  [
    {
        path: '/',
        element: <AppLayout />,
        children: [
            {
                path: '/',
                element: <Dashboard />
            },
            {
                path: 'profile',
                element: <AdminProfile />
            },
            {
                path: 'campaigns',
                element: <CampaignList />
            },
            {
                path: 'campaigns/new',
                element: <AddCampaign />
            },
            {
                path: 'campaigns/edit/:id',
                element: <EditCampaign />
            },
            ...DocumentsRoutes,
            ...PlatformsRoutes,
            ...LandingPageRoutes,
            ...MediaBuyingRoutes
        ]
    },
        ...CallbacksRoutes,
    {
        path: '*',
        element: <PageNotFound />
    }
]
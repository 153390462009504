import { gql } from '@apollo/client'

export const CORE_CAMPAIGN_FIELDS = gql`
    fragment CoreCampaignFields on Campaign {
        id
        name
        userStatus
        systemStatus
        user {
            email
            username
            firstName
            lastName
            timeZoneOffset
        }
        createdAt
    }
`

export const CAMPAIGN_FIELDS = gql`
    ${CORE_CAMPAIGN_FIELDS}
    fragment CampaignFields on Campaign {
        ...CoreCampaignFields
        notes
        purpose
        address
        geolocation {
            latitude
            longitude
        }
        radius
        keywords
    }
`

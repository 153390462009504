import { Col, Dropdown, Button, Image } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import CustomToggle from '../../layouts/components/CustomToggle'
import { useChart } from '../../utils/chart-hook'
import { logos } from '../../utils/platform-logos'

const PlatformAudienceChart = ({ name, data, audience }) => {
    data = data.map(obj => ({
        ...obj,
        ...audience[obj.name]
    }))

    const {
        series,
        options,
        currentPeriod,
        currentPlatform,
        changePeriod,
        changePlatform
    } = useChart('bar', data, ['audienceThisWeek', 'audienceThisMonth', 'audienceThisYear'], 'Audience', { colors: ['#08B1BA'] })

    return (
        <Col md={12} xl={7}>
            <Card data-aos="fade-up" data-aos-delay="1000" className="dashboard-step-4">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name} - {currentPlatform} {' '}
                            <Button variant="sm bg-white rounded-circle p-2 no-pointer">
                                <Image src={logos[currentPlatform]} alt={currentPlatform} className="img-fluid object-contain" style={{width: '20px', height: '20px'}}/>
                            </Button>
                        </h4>
                    </div>
                    <div className="d-flex flex-row">
                        <Dropdown style={{marginRight: '10px'}}>
                            <Dropdown.Toggle as={CustomToggle} href="#" variant=" text-secondary" id="dropdownMenuPlatform" aria-expanded="false">
                                Select Platform
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuPeriod">
                                {data.map((platform) => {
                                    return (<li key={platform.id} className="d-flex mt-1 align-items-center">
                                        <Dropdown.Item href="#" onClick={() => changePlatform(platform.id)}>
                                            <Button variant="sm bg-white rounded-circle no-pointer" style={{width: '22px', padding: '0.01rem'}}>
                                                <Image src={logos[platform.name]} alt={platform.name} className="img-fluid object-contain" style={{width: '10px', height: '10px'}}/>
                                            </Button>{' '}
                                            {platform.name}
                                        </Dropdown.Item>
                                    </li>)
                                })}
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} href="#" variant=" text-secondary" id="dropdownMenuButton3" aria-expanded="false">
                                {currentPeriod}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                                <li><Dropdown.Item href="#" onClick={() => changePeriod('week')}>This Week</Dropdown.Item></li>
                                <li><Dropdown.Item href="#" onClick={() => changePeriod('month')}>This Month</Dropdown.Item></li>
                                <li><Dropdown.Item href="#" onClick={() => changePeriod('year')}>This Year</Dropdown.Item></li>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="bar" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default PlatformAudienceChart
import _ from 'lodash'
import { removeClass, addClass } from './dom'

export const updateBodyClass = (choices, value) => {
    if(choices.length > 0) {
        choices.forEach((other) => {
            removeClass('body', other)
        });
    }
    if(_.isArray(value)) {
        _.forEach(value, (val) => {
            addClass('body', val)
        })
    } else {
        addClass('body', value)
    }
}

export const updateThemeScheme = (value) => {
    let className = []
    if(value === 'auto') {
        className.push('auto')
        if (matchMedia("(prefers-color-scheme: light)").matches) {
            className.push('light')
        } else {
            className.push('dark')
        }
    } else {
        className.push(value)
    }
    updateBodyClass(['light', 'dark', 'auto'], className)
}

export const getPhoneFormInputStyle = (theme, validated, isValid) => {
    const styles = {
        dark: {
            '--react-international-phone-border-radius': '0.25rem',
            '--react-international-phone-border-color': validated ? (isValid ? '#1AA053' : '#C03221') : 'rgba(255, 255, 255, 0.1)',
            '--react-international-phone-background-color': '#1E2746',
            '--react-international-phone-text-color': 'white',
            '--react-international-phone-selected-dropdown-item-background-color': '#19203A',
            '--react-international-phone-country-selector-background-color-hover': '#19203A',
            '--react-international-phone-height': '42px',
            '--react-international-phone-font-size': '1rem'
        },
        light: {
            '--react-international-phone-border-radius': '0.25rem',
            '--react-international-phone-border-color': validated ? (isValid ? '#1AA053' : '#C03221') : '#EEEEEE',
            '--react-international-phone-background-color': '#FFFFFF',
            '--react-international-phone-text-color': '#8A92A6',
            '--react-international-phone-selected-dropdown-item-background-color': '#F9F9F9',
            '--react-international-phone-country-selector-background-color-hover': '#F9F9F9',
            '--react-international-phone-height': '42px',
            '--react-international-phone-font-size': '1rem'
        }
    }

    return theme === 'auto' ?
        styles[matchMedia("(prefers-color-scheme: light)").matches ? 'light' : 'dark'] :
        styles[theme]
}
import React, { Fragment, useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Row } from 'react-bootstrap'
import Loader from '../layouts/components/Loader'
import AudienceCounter from '../components/dashboard/AudienceCounter'
import AudienceChart from '../components/dashboard/AudienceChart'
import AudiencePieChart from '../components/dashboard/AudiencePieChart'
import PlatformAudienceChart from '../components/dashboard/PlatformAudienceChart'
import EngagementLevelChart from '../components/dashboard/EngagementLevelChart'
import LiveCampaignPerformanceChart from '../components/dashboard/LiveCampaignPerformanceChart'
import ConversionRateChart from '../components/dashboard/ConversionRateChart'
import SentimentAnalysisChart from '../components/dashboard/SentimentAnalysisChart'
import _ from 'lodash'

const GET_PLATFORMS = gql`
    query Platforms($options: PlatformOptions, $where: PlatformWhere) {
        platforms(options: $options, where: $where) {
            id
            name
        }
    }`

const GET_CAMPAIGN = gql`
    query Campaigns {
        campaigns {
            audience
        }
    }`

const Dashboard = () => {
    const [totalAudience, setTotalAudience] = useState({})
    const [audience, setAudience] = useState([])

    const platforms = useQuery(GET_PLATFORMS, {
        variables: { options: { sort: [{ order: 'ASC' }] }, where: { status: 'ACTIVE' } }
    })

    const campaign = useQuery(GET_CAMPAIGN)

    useEffect(() => {
        if (!campaign.loading && campaign?.data?.campaigns && !platforms.loading && platforms?.data?.platforms) {
            let data

            if (campaign.data.campaigns.length > 0 && campaign.audience !== null) {
                data = campaign.data.campaigns.reduce((acc, campaign) => {
                    if (campaign.audience !== null) {
                        const campaignData = JSON.parse(campaign.audience)
                        Object.keys(campaignData).forEach(platformName => {
                            if (!acc[platformName]) {
                                acc[platformName] = {
                                    audience: 0,
                                    audienceThisWeek: Array(7).fill(0),
                                    audienceThisMonth: Array(30).fill(0),
                                    audienceThisYear: Array(12).fill(0)
                                }
                            }
                            acc[platformName].audience += campaignData[platformName].audience
                            acc[platformName].audienceThisWeek = acc[platformName].audienceThisWeek.map((val, i) => val + campaignData[platformName].audienceThisWeek[i])
                            acc[platformName].audienceThisMonth = acc[platformName].audienceThisMonth.map((val, i) => val + campaignData[platformName].audienceThisMonth[i])
                            acc[platformName].audienceThisYear = acc[platformName].audienceThisYear.map((val, i) => val + campaignData[platformName].audienceThisYear[i])
                        })
                    }
                    return acc
                }, {})
                if (Object.keys(data).length === 0) {
                    data = Object.values(platforms.data.platforms).reduce((acc, platform) => {
                        acc[platform.name] = {
                            audience: 0,
                            audienceThisWeek: Array(7).fill(0),
                            audienceThisMonth: Array(30).fill(0),
                            audienceThisYear: Array(12).fill(0)
                        }
                        return acc
                    }, {})
                }
            } else {
                data = Object.values(platforms.data.platforms).reduce((acc, platform) => {
                    acc[platform.name] = {
                        audience: 0,
                        audienceThisWeek: Array(7).fill(0),
                        audienceThisMonth: Array(30).fill(0),
                        audienceThisYear: Array(12).fill(0)
                    }
                    return acc
                }, {})
            }

            setAudience(data)

            const year = []
            const month = []
            const week = []

            Object.values(data).forEach(({ audienceThisYear }) => {
                audienceThisYear.forEach((value, index) => {
                    if (year[index] === undefined) {
                        year[index] = value
                    } else {
                        year[index] += value
                    }
                })
            })

            Object.values(data).forEach(({ audienceThisMonth }) => {
                audienceThisMonth.forEach((value, index) => {
                    if (month[index] === undefined) {
                        month[index] = value
                    } else {
                        month[index] += value
                    }
                })
            })

            Object.values(data).forEach(({ audienceThisWeek }) => {
                audienceThisWeek.forEach((value, index) => {
                    if (week[index] === undefined) {
                        week[index] = value
                    } else {
                        week[index] += value
                    }
                })
            })

            setTotalAudience({
                audienceThisWeek: week,
                audienceThisMonth: month,
                audienceThisYear: year
            })
        }
    }, [campaign, platforms])

    return (
        <Fragment>
            { platforms.loading || campaign.loading || audience.length === 0 ? (
                <Loader />
            ) : (
                <Row>
                    <AudienceCounter
                        platforms={platforms.data.platforms}
                        data={audience}
                    />
                    {!_.isEmpty(totalAudience) && (
                        <AudienceChart name={'Audience Built across All Platforms'} data={totalAudience}/>
                    )}
                    <AudiencePieChart
                        name={'Audience Structure'}
                        data={platforms.data.platforms}
                        audience={audience}
                    />
                    <PlatformAudienceChart
                        name={'Audience Built per Platform'}
                        data={platforms.data.platforms}
                        audience={audience}
                    />
                    <EngagementLevelChart name={'Engagement Level'}/>
                    <LiveCampaignPerformanceChart name={'Live Campaign Performance'}/>
                    <ConversionRateChart name={'Conversion'}/>
                    <SentimentAnalysisChart name={'Sentiment Analysis'}/>
                </Row>
            )}
        </Fragment>
    )
}

export default Dashboard
import React, { useState, useEffect, Fragment, useCallback, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Col, Form, FormCheck, Button, Spinner } from 'react-bootstrap'
import Joyride, { STATUS } from 'react-joyride'
import { GuidanceContext } from '../../context/guidance-contex'
import validator from 'validator'
import { useForm } from '../../utils/form-hook'
import { usePasswordValidation } from '../../utils/password-validation-hook'
import { googleMapsPlaces } from '../../utils/google-maps-places'
import { TagsInput } from 'react-tag-input-component'
import ShowErrors from '../../components/partials/ShowErrors'
import Tooltip from '../common/Tooltip'
import _ from 'lodash'

const CampaignForm = ({ data, updateData, callback, errors, mutation }) => {
    const [showKeywordsLimitMessage, setShowKeywordsMessage] = useState(false)
    const [showKeywordLengthLimitMessage, setShowKeywordLengthLimitMessage] = useState(false)
    const [useMyEmail, setUseMyEmail] = useState(true)
    const navigate = useNavigate()
    const saveButtonName = data ? ['Update', 'Updating'] : ['Create', 'Creating']
    const { guidance } = useContext(GuidanceContext)

    const [run, setRun] = useState(true)
    const [steps] = useState([
        {
            target: '.campaign-step-1',
            content: 'Enter First Name of the Campaign Manager',
        },
        {
            target: '.campaign-step-2',
            content: 'Enter Last Name of the Campaign Manager',
        },
        {
            target: '.campaign-step-3',
            content: 'Enter a unique username to log in to the Dashboard app',
        },
        {
            target: '.campaign-step-4',
            content:
                'Enter email address to receive notifications. If you want to use your email address, then check "Use My Email"',
        },
        {
            target: '.campaign-step-5',
            content: 'Enter a password to log in to the Dashboard app',
        },
        {
            target: '.campaign-step-6',
            content: 'Repeat the password again to confirm you set it up correctly',
        },
        {
            target: '.campaign-step-7',
            content: 'Enter a detailed name for the campaign to easily distinguish it from your other campaigns',
        },
        {
            target: '.campaign-step-8',
            content:
                'Enter the address for the point that will serve as the centre of the geolocation area for the campaign. Start typing address and then select it from the list',
        },
        {
            target: '.campaign-step-9',
            content:
                "Enter the radius in metres for the campaign's geolocation area. Maximum radius of 11000 metres is allowed",
        },
        {
            target: '.campaign-step-10',
            content:
                'Enter up to 5 keywords that best describe your campaign, pressing the Enter key after each keyword',
        },
        {
            target: '.campaign-step-10-a',
            content: 'Enter a purpose of your campaign to help the app generate more accurate AI reports',
        },
        {
            target: '.campaign-step-11',
            content: 'Enter a brief description of your campaign to help the app generate more accurate AI reports',
        },
        {
            target: '.campaign-step-12',
            content:
                'Set status of the campaign to Active because only active campaigns aggregate data, generate AI reports and purchase media',
        },
        {
            target: '.campaign-step-13',
            content:
                'Click this button to create your campaign. Please note that $1,770 AUD for the year period will be charged from your selected payment method',
        },
    ])

    const handleTourCallback = (data) => {
        const { status, type } = data
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false)
        }
    }

    const validateKeywordsInput = (keyword, keywords) => {
        return keywords.length < process.env.REACT_APP_MAX_KEYWORDS_NUMBER
    }

    const limitKeywordInput = (event) => {
        const value = event.target.value
        if (value.length > process.env.REACT_APP_MAX_KEYWORDS_LENGTH) {
            event.target.value = value.slice(0, -1)
            setShowKeywordLengthLimitMessage(true)
        } else {
            setShowKeywordLengthLimitMessage(false)
        }
        validateKeywords()
    }

    const setKeywords = (keywords) => {
        setValue((prevState) => {
            const length = keywords.length
            if (keywords[length - 1]) {
                keywords[length - 1] = keywords[length - 1].replace(/\s+/g, ' ').trim().toLowerCase()
            }
            if (keywords[length - 1]?.length > process.env.REACT_APP_MAX_KEYWORDS_LENGTH) {
                keywords[length - 1] = keywords[length - 1].substring(0, process.env.REACT_APP_MAX_KEYWORDS_LENGTH)
            }

            // Remove duplicated keywords
            if (keywords.slice(0, length - 1).includes(keywords[length - 1])) {
                keywords = keywords.splice(0, length - 1)
            }

            return {
                ...prevState,
                keywords: keywords,
            }
        })
    }

    const toggleUseMyEmail = () => {
        setUseMyEmail((prevState) => {
            return !prevState
        })
        setValue((prevState) => {
            return {
                ...prevState,
                email: '',
            }
        })
    }

    const usernameValidation = () => {
        const inputUsername = document.getElementById('username')

        if (validator.isAlphanumeric(values.username)) {
            inputUsername.setCustomValidity('')
        } else {
            inputUsername.setCustomValidity('Only Alphanumeric Characters are allowed')
        }
    }

    const { onChange, onSubmit, values, validated, validateEmail, setValue } = useForm(callback, {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: '',
        name: '',
        userStatus: 'ACTIVE',
        notes: '',
        purpose: '',
        address: '',
        geolocation: {
            latitude: null,
            longitude: null,
        },
        radius: '',
        keywords: [],
        timeZoneOffset: null,
        location: null,
    })

    const { passwordValidation } = usePasswordValidation(values)

    const validateKeywords = useCallback(() => {
        const inputKeywords = document.getElementsByName('keywords')[0]
        if (values.keywords.length > 0) {
            inputKeywords.setCustomValidity('')
        } else {
            inputKeywords.setCustomValidity('At least one keyword is required')
        }
    }, [values.keywords])

    useEffect(() => {
        if (values.location) {
            setValue((prevState) => {
                return {
                    ...prevState,
                    address: values.location.address || '',
                    geolocation: {
                        latitude: values.location.lat,
                        longitude: values.location.lng,
                    },
                    timeZoneOffset: values.location.offset || 0 * 60,
                }
            })
        }
    }, [values.location, setValue])

    useEffect(() => {
        googleMapsPlaces(
            setValue,
            document.getElementById('address'),
            document.getElementById('map'),
            document.getElementById('radius'),
            data
        )
    }, [data, setValue])

    useEffect(() => {
        setShowKeywordsMessage(values.keywords.length > 4)
        validateKeywords()
    }, [values.keywords, validateKeywords])

    useEffect(() => {
        const inputGeoLocation = document.getElementById('address')

        if (
            values.geolocation.latitude !== null &&
            values.geolocation.longitude !== null &&
            values.timeZoneOffset !== null
        ) {
            inputGeoLocation.setCustomValidity('')
        } else {
            inputGeoLocation.setCustomValidity('Geo Location was not defined')
        }
    }, [values.geolocation, values.timeZoneOffset])

    useEffect(() => {
        if (!_.isEmpty(data) && !values.firstName) {
            _.each(values, (value, key) => {
                switch (key) {
                    case 'firstName':
                    case 'lastName':
                    case 'email':
                    case 'username':
                    case 'timeZoneOffset':
                        setValue((prevState) => {
                            return { ...prevState, [key]: data.user[key] ? data.user[key] : '' }
                        })
                        break
                    case 'keywords':
                        setValue((prevState) => {
                            return { ...prevState, [key]: JSON.parse(data[key]) }
                        })
                        break
                    case 'geolocation':
                        setValue((prevState) => {
                            return {
                                ...prevState,
                                [key]: { latitude: data[key].latitude, longitude: data[key].longitude },
                            }
                        })
                        break
                    default:
                        if (data[key]) {
                            setValue((prevState) => {
                                return { ...prevState, [key]: data[key] ? data[key] : '' }
                            })
                        }
                }
            })

            if (data.user.email) {
                setUseMyEmail(false)
            }
        }
    }, [data, setValue, values])

    useEffect(() => {
        updateData(values)
    }, [values, updateData])

    return (
        <Fragment>
            <Joyride
                steps={steps}
                run={guidance === 'true' ? run : false}
                continuous={true}
                showProgress={false}
                showSkipButton={true}
                callback={handleTourCallback}
                styles={{ options: { primaryColor: '#437eeb' } }}
            />
            <Form noValidate validated={validated}>
                <h5 className="mb-3">Campaign Manager</h5>
                <div className="row">
                    <Form.Group className="col-md-6 form-group campaign-step-1">
                        <Form.Label htmlFor="fname">First Name:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            id="fname"
                            placeholder="Enter First Name"
                            name="firstName"
                            value={values.firstName}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group campaign-step-2">
                        <Form.Label htmlFor="lname">Last Name:</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            id="lname"
                            placeholder="Enter Last Name"
                            name="lastName"
                            value={values.lastName}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                </div>
                <hr />
                <h5 className="mb-3">Campaign Login Details</h5>
                <div className="row">
                    <Form.Group className="col-md-6 form-group campaign-step-3">
                        <Form.Label htmlFor="username">
                            Username:
                            <Tooltip text={'A unique username is required to log in to the Dashboard app'} />
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            id="username"
                            placeholder="Enter Username"
                            name="username"
                            value={values.username}
                            onChange={onChange}
                            onKeyUp={usernameValidation}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {values.username.length > 0 ? 'Only Alphanumeric Characters are allowed' : ''}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group campaign-step-4">
                        <Form.Label htmlFor="username">
                            Email:
                            <Tooltip
                                text={
                                    'Select the email address to receive notifications. You can use your own email or enter a different one'
                                }
                            />
                        </Form.Label>
                        <Form.Check className="form-check-inline" style={{ marginLeft: '30px' }}>
                            <FormCheck.Input
                                type="checkbox"
                                className="form-check-input"
                                id="useAdminEmail"
                                checked={useMyEmail}
                                onChange={toggleUseMyEmail}
                            />
                            <FormCheck.Label
                                className="form-check-label pl-2"
                                htmlFor="useAdminEmail"
                                name="useAdminEmail"
                            >
                                Use My Email
                            </FormCheck.Label>
                        </Form.Check>
                        <Form.Control
                            required
                            type="email"
                            id="email"
                            placeholder="Or Enter Another Email"
                            name="email"
                            value={values.email}
                            onChange={onChange}
                            disabled={useMyEmail}
                            onKeyUp={validateEmail}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group campaign-step-5">
                        <Form.Label htmlFor="password">
                            Password:
                            <Tooltip
                                text={
                                    'Set up a password for logging in to the Dashboard app. Enter a new password to change the existing one'
                                }
                            />
                        </Form.Label>
                        <Form.Control
                            required={!data}
                            type="password"
                            id="password"
                            placeholder={data ? 'Enter New Password to Update' : 'Enter Password'}
                            name="password"
                            onChange={onChange}
                            onKeyUp={passwordValidation}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {values.password.length > 0 ? 'Weak Password' : ''}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group campaign-step-6">
                        <Form.Label htmlFor="confirmPassword">
                            Repeat Password:
                            <Tooltip
                                text={
                                    'Set up a password for logging in to the Dashboard app. Enter a new password to change the existing one'
                                }
                            />
                        </Form.Label>
                        <Form.Control
                            required={!data}
                            type="password"
                            id="confirmPassword"
                            placeholder={data ? 'Enter New Password to Update' : 'Confirm Password'}
                            name="confirmPassword"
                            onChange={onChange}
                            onKeyUp={passwordValidation}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                            {values.password !== values.confirmPassword ? 'Passwords do not match' : ''}
                        </Form.Control.Feedback>
                    </Form.Group>
                </div>
                <hr />
                <h5 className="mb-3">Campaign Settings</h5>
                <div className="row">
                    <Col lg="6">
                        <Form.Group className="col-md-12 form-group campaign-step-7">
                            <Form.Label htmlFor="name">
                                Name:
                                <Tooltip
                                    text={
                                        'Enter a detailed name for the campaign to easily distinguish it from other campaigns'
                                    }
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                id="name"
                                placeholder="Enter Campaign Name"
                                name="name"
                                value={values.name}
                                onChange={onChange}
                            ></Form.Control>
                        </Form.Group>
                        <Form.Group className="col-md-12 form-group campaign-step-8">
                            <Form.Label htmlFor="address">
                                Geo Location (Address):
                                <Tooltip
                                    text={
                                        'Enter the address for the point that will serve as the center of the geolocation area for the campaign'
                                    }
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                type="text"
                                id="address"
                                placeholder="Enter Address"
                                name="address"
                                value={values.address}
                                onChange={onChange}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {values.address?.length > 0 ? 'Address was not recognised' : ''}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="col-md-12 form-group campaign-step-9">
                            <Form.Label htmlFor="radius">
                                Radius (m):
                                <Tooltip
                                    text={`Enter the radius in metres for the campaign\'s geolocation area. Maximum radius of ${process.env.REACT_APP_MAX_GEOLOCATION_RADIUS} metres is allowed`}
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                type="number"
                                id="radius"
                                min="1"
                                max={process.env.REACT_APP_MAX_GEOLOCATION_RADIUS}
                                placeholder="Enter Radius in Metres"
                                name="radius"
                                value={values.radius}
                                onChange={onChange}
                            ></Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {values.radius.length > 0
                                    ? `Maximum radius of ${process.env.REACT_APP_MAX_GEOLOCATION_RADIUS} metres is allowed`
                                    : 'Radius must be a number'}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            className={`col-md-12 form-group ${
                                validated && values.keywords.length === 0 && 'invalid'
                            } campaign-step-10`}
                        >
                            <Form.Label htmlFor="radius">
                                Keywords:
                                <Tooltip
                                    text={
                                        'Enter up to 5 keywords that best describe your campaign, pressing the Enter key after each keyword'
                                    }
                                />
                            </Form.Label>
                            <TagsInput
                                id="keywords"
                                value={values.keywords}
                                onChange={setKeywords}
                                name="keywords"
                                placeHolder="Enter keywords"
                                beforeAddValidate={validateKeywordsInput}
                                onKeyUp={limitKeywordInput}
                            />
                            {showKeywordsLimitMessage && (
                                <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                    Maximum {process.env.REACT_APP_MAX_KEYWORDS_NUMBER} keywords are allowed
                                </Form.Control.Feedback>
                            )}
                            {showKeywordLengthLimitMessage && (
                                <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                    Maximum {process.env.REACT_APP_MAX_KEYWORDS_LENGTH} characters are allowed
                                </Form.Control.Feedback>
                            )}
                            {validated && values.keywords.length === 0 && (
                                <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
                                    At least one keyword is required
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group className="col-md-12 form-group campaign-step-10-1">
                            <Form.Label htmlFor="notes">
                                Campaign Purpose
                                <Tooltip
                                    text={'Enter a purpose of your campaign to help us generate more accurate reports'}
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                id="purpose"
                                rows="3"
                                name="purpose"
                                onChange={onChange}
                                value={values.purpose}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-12 form-group campaign-step-11">
                            <Form.Label htmlFor="notes">
                                Campaign Short Brief
                                <Tooltip
                                    text={
                                        'Enter a brief description of your campaign to help us generate more accurate reports'
                                    }
                                />
                            </Form.Label>
                            <Form.Control
                                required
                                as="textarea"
                                id="notes"
                                rows="3"
                                name="notes"
                                onChange={onChange}
                                value={values.notes}
                            />
                        </Form.Group>
                        <Form.Group className="col-md-12 form-group campaign-step-12">
                            <Form.Label htmlFor="userStatus" style={{ marginRight: '30px' }}>
                                Campaign Status:
                                <Tooltip
                                    text={
                                        'Only active campaigns aggregate data, generate AI reports, and purchase media'
                                    }
                                />
                            </Form.Label>
                            <Form.Check className="form-check-inline">
                                <FormCheck.Input
                                    type="radio"
                                    name="userStatus"
                                    id="statusActive"
                                    value="ACTIVE"
                                    checked={values.userStatus === 'ACTIVE'}
                                    onChange={onChange}
                                />
                                <FormCheck.Label htmlFor="statusActive" className="pl-2">
                                    Active
                                </FormCheck.Label>
                            </Form.Check>
                            <Form.Check className="form-check-inline">
                                <FormCheck.Input
                                    type="radio"
                                    name="userStatus"
                                    id="statusInactive"
                                    value="INACTIVE"
                                    checked={values.userStatus === 'INACTIVE'}
                                    onChange={onChange}
                                />
                                <FormCheck.Label htmlFor="statusInactive" className="pl-2">
                                    Inactive
                                </FormCheck.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col>
                    <Col lg="6">
                        <div id="map" style={{ height: '525px' }}></div>
                    </Col>
                </div>
                <div className="pb-3 pt-3">
                    <ShowErrors errors={errors} />
                </div>
                <Button
                    type="button"
                    variant="btn btn-primary"
                    onClick={onSubmit}
                    disabled={mutation.loading}
                    className="campaign-step-13"
                >
                    {mutation.loading ? (
                        <Fragment>
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                            {' ' + saveButtonName[1]}...
                        </Fragment>
                    ) : (
                        <Fragment>{saveButtonName[0]}</Fragment>
                    )}
                </Button>{' '}
                <Button
                    type="button"
                    variant="btn btn-danger"
                    onClick={() => {
                        navigate('/campaigns')
                    }}
                    disabled={mutation.loading}
                >
                    Cancel
                </Button>
            </Form>
        </Fragment>
    )
}

export default CampaignForm

import { useEffect, useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { OAuth2Context } from '../../context/oauth2-context'
import { CAMPAIGN_FIELDS } from '../../graphql/campaign-fragments'
import Loader from '../../layouts/components/Loader'

const CONNECT_CAMPAIGN_TO_PLATFORM = gql`
    ${CAMPAIGN_FIELDS}
    mutation ConnectCampaignToPlatform($oAuthPlatformInput: OAuthPlatformInput) {
        connectCampaignToPlatform(oAuthPlatformInput: $oAuthPlatformInput) {
            ...CampaignFields
            platforms {
                id
            }
        }
    }
`

const OAuthCallback = (props) => {
    const context = useContext(OAuth2Context)
    const navigate = useNavigate()
    const [code, setCode] = useState(null)

    const [connectCampaignToPlatform] = useMutation(CONNECT_CAMPAIGN_TO_PLATFORM, {
        onError({ graphQLErrors }) {
            context.removeState()
            navigate('/campaigns/platforms/' + context.campaignId, {
                state: {
                    notification: {
                        status: 'error',
                        message: graphQLErrors[0]?.message
                    }
                }
            })
        },
        onCompleted: () => {
            context.removeState()
            navigate('/campaigns/platforms/' + context.campaignId, {
                state: {
                    notification: {
                        status: 'success',
                        message: 'The platform has been connected successfully!'
                    }
                }
            })
        },
        variables: {
            oAuthPlatformInput: {
                id: context.platform,
                code: code,
                campaignId: context.campaignId,
                redirectUri: `${document.location.origin}/callback`
            }
        }
    })

    useEffect(() => {
        if (window.location.pathname !== '/callback') return
        const queryParams = new URLSearchParams(window.location.search)
        const state = queryParams.get('state')
        const error = queryParams.get('error')
        const code = queryParams.get('code')
        if (state !== context.state || !code || error) {
            context.removeState()
            navigate('/campaigns/platforms/' + context.campaignId, {
                state: {
                    notification: {
                        status: 'error',
                        message: error || 'Could not connect platform'
                    }
                }
            })
        }
        setCode(code)
    }, [context, navigate])

    useEffect(() => {
        if (code) {
            connectCampaignToPlatform()
        }
    }, [code, connectCampaignToPlatform])

    return <Loader />
}

export default OAuthCallback

import { useContext } from 'react'
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import { AuthContext } from '../../context/auth-context'

const EngagementLevelChart = ({ name }) => {
    const { user } = useContext(AuthContext)

    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const today = new Date()
    const last9Months = []

    for (let i = 8; i >= 0; i--) {
        const date = new Date(today.getFullYear(), today.getMonth() - i, 1)
        last9Months.push(monthNames[date.getMonth()])
    }

    const options = {
        chart: {
            stacked: false,
            toolbar: {
                show: false
            }
        },
        colors: ['#427EEB'],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded',
                borderRadius: 3
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: last9Months,
            labels: {
                style: {
                    colors: '#8A92A6'
                }
            }
        },
        yaxis: {
            title: {
                text: ''
            },
            labels: {
                minWidth: 19,
                maxWidth: 19,
                style: {
                    colors: '#8A92A6'
                }
            }
        },
        fill: {
            opacity: 1
        }
    }

    const series = [{
        name: 'Page View',
        data: process.env.REACT_APP_DEMO_USER_ID === user.id
            ? [760, 850, 1010, 980, 870, 1050, 910, 1140, 940]
            : [0, 0, 0, 0, 0, 0, 0, 0, 4125]
    }]

    return (
        <Col md={12} xl={5}>
            <Card data-aos="fade-up" data-aos-delay="1000" className="dashboard-step-5">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="bar" height="320" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default EngagementLevelChart
import { useState } from 'react'
import _ from 'lodash'

const BAR_CHART_OPTIONS=  {
    chart: {
        stacked: true,
        toolbar: {
            show: false
        }
    },
    colors: ['#427EEB'],
    plotOptions: {
        bar: {
            horizontal: false,
            columnWidth: '40%',
            endingShape: 'rounded',
            borderRadius: 5
        }
    },
    legend: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
    },
    xaxis: {
        categories: null,
        labels: {
            minHeight: 20,
            maxHeight: 20,
            style: {
                colors: '#8A92A6'
            }
        }
    },
    yaxis: {
        title: {
            text: ''
        },
        labels: {
            minWidth: 19,
            maxWidth: 19,
            style: {
                colors: '#8A92A6'
            }
        }
    },
    fill: {
        opacity: 1
    }
}

const PIE_CHART_OPTIONS = {
    theme: {
        monochrome: {
            enabled: true,
            color: '#427EEB',
            shadeTo: 'light'
        }
    },
    responsive: [{
        breakpoint: 480,
        options: {
            chart: {
                width: 250
            },
            legend: {
                position: 'bottom'
            }
        }
    }],
    dataLabels: {
        enabled: false,
    }
}

const LINE_CHART_OPTIONS = {
    chart: {
        type: 'line',
        toolbar: {
            show: false
        }
    },
    colors: ['#427EEB', '#08B1BA', '#F16a1B'],
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'smooth'
    },
    xaxis: {
        categories: null,
        labels: {
            style: {
                colors: '#8A92A6'
            }
        }
    },
    yaxis: {
        title: {
            text: ''
        },
        labels: {
            minWidth: 19,
            maxWidth: 19,
            style: {
                colors: '#8A92A6'
            }
        }
    },
    fill: {
        opacity: 1
    }
}

const labels = (period) => {
    switch (period){
        case 'week':
        default:
            return _.map(_.range(7, 0, -1), (number) => {
                const date = new Date()
                date.setDate(date.getDate() - number + 1)
                return (new Intl.DateTimeFormat('en-AU', {day: 'numeric', month: 'short'})).format(date)
            })
        case 'month':
            return  _.map(_.range(30, 0, -1), (number) => {
                const date = new Date()
                date.setDate(date.getDate() - number + 1)
                return (new Intl.DateTimeFormat('en-AU',{ day: 'numeric', month: 'short' })).format(date)
            })
        case 'year':
            return _.map(_.range(12, 0, -1), (number) => {
                const date = new Date()
                date.setMonth(date.getMonth() - number + 1)
                return (new Intl.DateTimeFormat('en-AU',{ month: 'short'})).format(date)
            })
    }
}

const optionsTemplate = (type) => {
    switch (type) {
        case 'bar':
            return BAR_CHART_OPTIONS
        case 'pie':
            return PIE_CHART_OPTIONS
        case 'line':
            return LINE_CHART_OPTIONS
        default:
            return {}
    }
}

const initialOptions = (type) => {
    switch (type) {
        case 'bar':
        case 'line':
            return {
                xaxis: {
                    categories: labels('week')
                }
            }
        default:
            return {}
    }
}

const selectDataSet = (period, properties) => {
    switch (period) {
        case 'month':
            return properties[1]
        case 'year':
            return properties[2]
        case 'week':
        default:
            return properties[0]
    }
}

export const useChart = (type, data, properties, seriesName, specialOptions) => {
    const [index, setIndex] = useState(0)
    const [currentPeriod, setCurrentPeriod] = useState('This Week')
    const [currentPlatform, setCurrentPlatform] = useState(_.isArray(data) ? data[index].name : null)
    const [options, setOptions] = useState({
        ...optionsTemplate(type),
        ...initialOptions(type),
        ...specialOptions
    })
    const [series, setSeries] = useState(type === 'line'
        ? (data ? data[properties[0]] : [])
        : [{
            name: seriesName,
            data: _.isArray(data) ? data[index][properties[0]] : data[properties[0]]
        }]
    )

    const changePeriod = (period) => {
        const dataSet = type === 'line'
            ? data
            : (_.isArray(data) ? data[index] : data)
        const seriesData = type === 'line'
            ? dataSet[selectDataSet(period, properties)]
            : { data: dataSet[selectDataSet(period, properties)] }
        const optionsData = {
            xaxis: {
                categories: labels(period)
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: period === 'month' ? '70%' : '40%',
                    endingShape: 'rounded',
                    borderRadius: 5
                }
            }
        }

        setSeries((prevState) => {
            return type === 'line'
                ? seriesData
                : [{
                    ...prevState,
                    ...seriesData
                }]
        })
        setOptions((prevState) => {
            return {
                ...prevState,
                ...optionsData
            }
        })
        setCurrentPeriod('This ' + period.charAt(0).toUpperCase() + period.slice(1))
    }

    const changePlatform = (platformId) => {
        const index = _.findIndex(data, (platform) => platform.id === platformId)
        const period = currentPeriod.split(' ')[1].toLowerCase()
        const seriesData = { data: data[index][selectDataSet(period, properties)] }

        setCurrentPlatform(data[index].name)
        setIndex(index)
        setSeries((prevState) => {
            return [{
                ...prevState,
                ...seriesData
            }]
        })
    }

    return { series, options, currentPeriod, currentPlatform, changePeriod, changePlatform, setSeries }
}
import { useCallback, useContext } from 'react'
import { OAuth2Context } from '../context/oauth2-context'

const generateState = () => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let array = new Uint8Array(40)
    window.crypto.getRandomValues(array)
    array = array.map((x) => validChars.codePointAt(x % validChars.length))
    return String.fromCharCode.apply(null, array)
}

const formatAuthorizeUrl = (authorizeUrl, clientId, redirectUri, scope, state, responseType, extraQueryParameters) => {
    const query = objectToQuery({
        response_type: responseType,
        client_id: clientId,
        redirect_uri: redirectUri,
        scope,
        state,
        ...extraQueryParameters
    })
    return `${authorizeUrl}?${query}`
}

const objectToQuery = (object) => {
    return new URLSearchParams(object).toString()
}

export const useOAuth2 = (props) => {
    const { campaignId, responseType, redirectUri } = props

    const context = useContext(OAuth2Context)

    const getAuth = useCallback((parameters) => {
        const state = generateState()
        context.setState({
            state: state,
            platform: parameters.id,
            campaignId: campaignId
        })

        const extraQueryParameters = parameters.extra ? {
            ...props.extraQueryParameters,
            ...JSON.parse(parameters.extra)
            } : props.extraQueryParameters

        window.location.replace(formatAuthorizeUrl(
            parameters.authorizeUrl,
            parameters.clientId,
            redirectUri,
            parameters.scope,
            state,
            responseType,
            extraQueryParameters
        ))
    }, [redirectUri, campaignId, context, responseType, props.extraQueryParameters])

    return { getAuth }
}
import React, { Fragment, useContext, useState, useEffect } from 'react'
import { gql, useQuery } from '@apollo/client'
import { Col, Dropdown } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import CustomToggle from '../../layouts/components/CustomToggle'
import Card from '../common/Card'
import { AuthContext } from '../../context/auth-context'
import Loader from '../../layouts/components/Loader'

const GET_INSIGHTS = gql`
    query Publishers($where: PublisherCampaignsConnectionWhere, $publishersWhere2: PublisherWhere) {
        publishers(where: $publishersWhere2) {
            name
            campaignsConnection(where: $where) {
                edges {
                    conversion
                    conversionPerAds
                }
            }
        }
    }`

const ConversionRateChart = ({ name }) => {
    const { user } = useContext(AuthContext)
    const [conversion, setConversion] = useState('')
    const [conversionRate, setConversionRate] = useState(0)

    const insights = useQuery(GET_INSIGHTS)

    useEffect(() => {
        if (insights.data?.publishers.length > 0) {
            const data = insights.data.publishers.find((publisher) => publisher.name === 'Meta').campaignsConnection.edges

            const conversion = data.reduce((acc, obj) => {
                return acc + obj.conversion
            }, 0)

            const rates = data.map((campaign) => JSON.parse(campaign.conversionPerAds))
            const flattenedObject = rates.reduce((acc, obj) => {
                return { ...acc, ...obj }
            }, {})

            setConversionRate(Math.max(...Object.values(flattenedObject)))
            setConversion(conversion.toString())
        }
    }, [insights.data])

    const chart ={
        options : {
            labels: ['Conversions'],
            chart: {
                type: 'radialBar',
                offsetY: -20,
                sparkline: {
                    enabled: true
                },
            },
            colors: ['#08B1BA'],

            plotOptions: {
                radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    track: {
                        background: '#3a57e81a',
                        show: true,
                        strokeWidth: '97%',
                        opacity: 1,
                        margin: 5,
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    dataLabels: {
                        name: {
                            fontSize: '16px',
                            offsetY: 20,
                        },
                        value: {
                            offsetY: -25,
                            fontSize: '40px',
                            formatter: function () {
                                return process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? '754' : conversion
                            }
                        },

                    }
                }
            },
            grid: {
                padding: {
                    top: -10
                }
            },
        },
        series: process.env.REACT_APP_DEMO_USER_ID.includes(user.id) ? [75] : [conversionRate]
    }

    return (
        <Fragment>
            { insights.loading || conversion.length === 0 ? (
                <Loader fullScreen={false} />
            ) : (
                <Col md={12} xl={4}>
                    <Card data-aos="fade-up" data-aos-delay="900" className="dashboard-step-7">
                        <Card.Header className="flex-wrap d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">{name}</h4>
                            </div>
                            {/*<Dropdown>*/}
                            {/*    <Dropdown.Toggle as={CustomToggle} href="#" variant="text-secondary dropdown-toggle" size="sm" id="dropdownMenuButton1">*/}
                            {/*        This Week*/}
                            {/*    </Dropdown.Toggle>*/}
                            {/*    <Dropdown.Menu>*/}
                            {/*        <Dropdown.Item href="#">This Week</Dropdown.Item>*/}
                            {/*        <Dropdown.Item href="#">This Month</Dropdown.Item>*/}
                            {/*        <Dropdown.Item href="#">This Year</Dropdown.Item>*/}
                            {/*    </Dropdown.Menu>*/}
                            {/*</Dropdown>*/}
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex align-items-center justify-content-center" style={{ height: '300px', textAlign: 'center' }}>
                                <Chart options={chart.options} series={chart.series} type="radialBar" height="375" />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            )}
        </Fragment>
    )
}

export default ConversionRateChart
import { Col } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import Card from '../common/Card'
import { useChart } from '../../utils/chart-hook'
import _ from 'lodash'

const THRESHOLD = 1

const AudiencePieChart = ({ name, data, audience }) => {
    data = data.map(obj => ({
        ...obj,
        ...audience[obj.name]
    }))

    const { options } = useChart('pie', data, ['audienceThisWeek', 'audienceThisMonth', 'audienceThisYear'])

    const rawSeries = _.map(data, (platform) => platform.audience)

    const totalSum = _.sum(rawSeries)
    const threshold = totalSum * THRESHOLD / 100

    let othersSum = 0
    const filteredData = _.filter(data, (platform) => {
        if (platform.audience >= threshold) {
            return true
        } else {
            othersSum += platform.audience
            return false
        }
    })

    const labels = _.map(filteredData, (platform) => platform.name)
    const series = _.map(filteredData, (platform) => platform.audience)

    if (othersSum > 0) {
        labels.push('Others')
        series.push(othersSum)
    }

    options['labels'] = labels

    return (
        <Col md={12} xl={5}>
            <Card data-aos="fade-up" data-aos-delay="1000" className="dashboard-step-3">
                <div className="flex-wrap card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">{name}</h4>
                    </div>
                </div>
                <Card.Body>
                    <Chart className="d-activity" options={options} series={series} type="pie" height="300" />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default AudiencePieChart
import { Loader } from '@googlemaps/js-api-loader'
import _ from 'lodash'

export const googleMapsPlaces = (setValue, addressElement, mapElement = null, radiusElement = null, data = null) => {
    const loader = new Loader({
        apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
        version: 'weekly',
        libraries: ['places', 'maps'],
    })

    loader.load().then(async (google) => {
        const { Map } = await google.maps.importLibrary('maps')
        let marker = null
        let point = null
        let circle = null
        let radius = null
        let map = null
        const zoomThreshold = 10 // Define your zoom level threshold

        const handleZoomChange = () => {
            const zoomLevel = map.getZoom()
            if (zoomLevel < zoomThreshold) {
                if (marker) {
                    marker.setMap(null)
                }
                if (circle) {
                    circle.setMap(null)
                }
            } else {
                if (marker) {
                    marker.setMap(map)
                    marker.setDraggable(true)
                }
                if (point) {
                    setCircle()
                }
            }
        }

        if (mapElement) {
            map = new Map(mapElement, {
                center: { lat: 0, lng: 0 },
                zoom: 1,
            })

            map.addListener('zoom_changed', handleZoomChange)
        }

        const autocomplete = new google.maps.places.Autocomplete(addressElement)

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()
            setValue((prevState) => {
                return {
                    ...prevState,
                    location: retrieveAddress(place),
                }
            })

            if (map && place.geometry) {
                map.panTo(place.geometry.location)

                setPoint({
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                })
            }
        })

        const setCircle = () => {
            if (circle) {
                circle.setMap(null)
            }
            circle = new google.maps.Circle({
                strokeColor: '#f38038',
                strokeOpacity: 0.4,
                strokeWeight: 2,
                fillColor: '#f38038',
                fillOpacity: 0.25,
                map: map,
                center: new google.maps.LatLng(point.lat, point.lng),
                radius: radius,
            })
        }

        if (radiusElement && mapElement) {
            radiusElement.addEventListener('change', (event) => {
                radius = parseInt(event.target.value)
                if (point && radius) {
                    setCircle()
                }
                setValue((prevState) => {
                    return {
                        ...prevState,
                        radius: event.target.value,
                    }
                })
            })
        }

        const setPointOnTheMap = (zoom) => {
            map.setZoom(zoom ? 15 : 8)
            if (marker) {
                marker.setPosition(pointLatLng())
            } else {
                marker = new google.maps.Marker({
                    position: pointLatLng(),
                    map: map,
                    draggable: true, // Make the marker draggable
                })

                marker.addListener('dragend', () => {
                    const newPosition = marker.getPosition()
                    const geocoder = new google.maps.Geocoder()

                    geocoder.geocode({ location: newPosition }, (results, status) => {
                        if (status === 'OK') {
                            if (results[0]) {
                                const placeId = results[0].place_id
                                const placesService = new google.maps.places.PlacesService(map)
                                placesService.getDetails({ placeId }, (place, placeStatus) => {
                                    if (placeStatus === 'OK') {
                                        const updatedAddress = retrieveAddress(place)
                                        setValue((prevState) => {
                                            return {
                                                ...prevState,
                                                location: updatedAddress,
                                            }
                                        })
                                        point = {
                                            lat: place.geometry.location.lat(),
                                            lng: place.geometry.location.lng(),
                                        }
                                        if (radius) {
                                            setCircle()
                                        }
                                    }
                                })
                            }
                        }
                    })
                })
            }
            if (radius) {
                setCircle()
            }
        }

        const pointLatLng = () => {
            return new google.maps.LatLng(point.lat, point.lng)
        }

        const setPoint = (address) => {
            point = {
                lat: address?.lat || 0,
                lng: address?.lng || 0,
            }

            setPointOnTheMap(address?.lat)
        }

        const retrieveAddress = (place) => {
            const length = place?.address_components?.length
            if (length >= 6) {
                return {
                    address: place.formatted_address || '',
                    line1:
                        length > 6
                            ? length > 7
                                ? place.address_components[length - 8]?.short_name +
                                  ' ' +
                                  place.address_components[length - 7]?.short_name
                                : place.address_components[length - 7]?.short_name
                            : place.address_components[length - 6]?.short_name,
                    line2: length > 6 ? place.address_components[length - 6]?.short_name : '',
                    suburb: place.address_components[length - 5]?.short_name,
                    city: place.address_components[length - 4]?.short_name,
                    postcode: place.address_components[length - 1]?.short_name,
                    state: place.address_components[length - 3]?.short_name,
                    country: place.address_components[length - 2]?.short_name,
                    lat: place.geometry?.location?.lat(),
                    lng: place.geometry?.location.lng(),
                    offset: place.utc_offset_minutes,
                }
            }
        }

        if (data && !_.isEmpty(data)) {
            if (map) {
                const location = {
                    lat: data.geolocation.latitude,
                    lng: data.geolocation.longitude,
                }
                map.panTo(location)
                setPoint(location)

                if (data.radius) {
                    radius = data.radius
                    setCircle()
                }
            }
        }
    })
}

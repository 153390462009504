import React, { useReducer, createContext } from 'react'

const initialState = {
    guidance: 'false'
}

if (localStorage.getItem('guidance')) {
    initialState.guidance = localStorage.getItem('guidance')
}

const GuidanceContext = createContext({
    guidance: 'false',
    updateGuidance: (theme) => {}
})

const settingsReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE':
            return {
                ...state,
                guidance: action.payload
            }
        default:
            return state
    }
}

const GuidanceProvider = (props) => {
    const [state, dispatch] = useReducer(settingsReducer, initialState)

    const updateGuidance = (guidance) => {
        localStorage.setItem('guidance', guidance)
        dispatch({
            type: 'UPDATE',
            payload: guidance
        })
    }

    return (
        <GuidanceContext.Provider
            value={{ guidance: state.guidance, updateGuidance }}
            { ...props }
        />
    )
}

export { GuidanceContext, GuidanceProvider }
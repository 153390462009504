import React, { memo, Fragment, useContext, useState } from 'react'
import { AuthContext } from '../context/auth-context'
import { SettingsContext } from '../context/settings-context'
import { useForm } from '../utils/form-hook'
import { useDotsAnimation } from '../utils/dot-animation-hook'
import { gql, useMutation } from '@apollo/client'
import { Row, Col, FormCheck, Form, Button, Alert, Spinner } from 'react-bootstrap'
import Card from '../components/common/Card'
import LogoDark from '../assets/images/logo-text-dark.png'
import LogoLight from '../assets/images/logo-text-light.png'
import { Link, useNavigate } from 'react-router-dom'
import Notification from '../components/partials/Notification'

const LOGIN_USER = gql`
    mutation Login($loginInput: LoginInput) {
        login(loginInput: $loginInput) {
            token
        }
    }
`

const Login = memo(() => {
    const { theme } = useContext(SettingsContext)
    const context = useContext(AuthContext)
    const navigate = useNavigate()
    const [errors, setError] = useState([])

    const loginUserCallback = (event) => {
        loginUser()
    }

    const { onChange, onSubmit, values, validated, validateEmail } = useForm(loginUserCallback, {
        email: '',
        password: '',
        context: 'admin',
    })

    const { animationRef } = useDotsAnimation()

    const [loginUser, { loading }] = useMutation(LOGIN_USER, {
        update(proxy, { data: { login: userData } }) {
            context.login(userData)
            navigate('/')
        },
        onError({ graphQLErrors }) {
            setError(graphQLErrors)
        },
        variables: { loginInput: values },
    })

    return (
        <Fragment>
            <section className="iq-auth-page" ref={animationRef}>
                <Row className="d-flex align-items-center justify-content-center vh-100 w-100">
                    <Col md="4" xl="4">
                        <Card className="p-4">
                            <Card.Body>
                                <div className="text-center" style={{ marginBottom: '30px' }}>
                                    <img
                                        src={theme === 'dark' ? LogoDark : LogoLight}
                                        alt="Turba Media"
                                        style={{ height: '45px' }}
                                    />
                                </div>
                                <h3 className="text-center">Sign In</h3>
                                <p className="text-center">Admin</p>
                                <Form noValidate validated={validated}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Email address</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            className="mb-0"
                                            placeholder="Enter email"
                                            name="email"
                                            onChange={onChange}
                                            onKeyUp={validateEmail}
                                        ></Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {values.email.length > 0 ? 'Please enter a valid Email' : ''}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            className="mb-0"
                                            placeholder="Enter password"
                                            name="password"
                                            onChange={onChange}
                                        ></Form.Control>
                                    </Form.Group>
                                    <div className="d-flex justify-content-between">
                                        <Form.Check className="d-inline-block mt-2 pt-1">
                                            <FormCheck.Input type="checkbox" id="customCheck11"></FormCheck.Input>
                                            <Form.Check.Label htmlFor="customCheck11">Remember Me</Form.Check.Label>
                                        </Form.Check>
                                        <Link to="/forgot-password" className="d-inline-block mt-2 pt-1">
                                            Forgot password?
                                        </Link>
                                    </div>
                                    <div className="pb-3">
                                        {errors.map((error, key) => {
                                            return (
                                                <Alert
                                                    key={key}
                                                    variant="warning rounded-0 alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    <span> {error.message} </span>
                                                </Alert>
                                            )
                                        })}
                                    </div>
                                    <div className="text-center pb-3">
                                        <Button
                                            type="button"
                                            variant="btn btn-primary"
                                            onClick={onSubmit}
                                            disabled={loading}
                                        >
                                            {loading ? (
                                                <Fragment>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    Signing in...
                                                </Fragment>
                                            ) : (
                                                <Fragment>Sign in</Fragment>
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                                <p className="text-center">Or sign in with other accounts?</p>
                                <p className="text-center">
                                    Don't have an account? <Link to="/signup">Click here to sign up.</Link>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
            <Notification />
        </Fragment>
    )
})

Login.displayName = 'Login'
export default Login

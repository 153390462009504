import React, { memo, Fragment, useState, useContext, useEffect, useRef } from 'react'
import Joyride, { STATUS } from 'react-joyride'
import { AuthContext } from '../../context/auth-context'
import { GuidanceContext } from '../../context/guidance-contex'
import { gql, useQuery } from '@apollo/client'
import { USER_FIELDS } from '../../graphql/user-fragments'
import { Row, Col } from 'react-bootstrap'
import { Elements } from '@stripe/react-stripe-js'
import { stripe, appearance } from '../../utils/stripe'
import PaymentDetails from '../../components/users/PaymentDetails'
import UserProfile from '../../components/users/UserProfile'
import Loader from '../../layouts/components/Loader'
import { showNotification } from '../../utils/notifications'
import { ToastContainer } from 'react-toastify'

const GET_USER = gql`
    ${USER_FIELDS}
    query Users($where: UserWhere) {
        users(where: $where) {
            ...UserFields
        }
    }
`

const AdminProfile = memo(() => {
    const { user } = useContext(AuthContext)
    const { guidance } = useContext(GuidanceContext)
    const [userData, setUserData] = useState([])
    const { data, loading } = useQuery(GET_USER, {
        variables: { where: { id: user.id } },
    })

    const guidedRef = useRef(false)

    const [run, setRun] = useState(false)
    const [steps] = useState([
        {
            target: '.step-1',
            content: 'Enter your phone number',
        },
        {
            target: '.step-2',
            content: 'Start typing your address and then select it from the list',
        },
        {
            target: '.step-3',
            content: 'Click this button to save your details',
        },
        {
            target: '.step-4',
            content: 'Click this button to add payment details and enter your credit card details',
        },
    ])

    const handleTourCallback = (data) => {
        const { status, type } = data
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false)
        }
    }

    const updateUserData = (userData) => {
        setUserData(userData)
    }

    useEffect(() => {
        if (guidance === 'true' && !guidedRef.current) {
            showNotification(
                'Please follow step by step guidance and enter your phone number, address and your credit card information. Note: Each campaign subscription lasts 12 months and costs $1,770 AUD',
                'info',
                false
            )
            guidedRef.current = true
        }
    }, [guidance])

    useEffect(() => {
        if (!loading && data && data.users && data.users.length > 0) {
            const checkElements = setInterval(() => {
                const allTargetsMounted = steps.every((step) => document.querySelector(step.target))
                if (allTargetsMounted) {
                    clearInterval(checkElements)
                    setRun(true)
                }
            }, 100)
        }
    }, [loading, data, steps])

    return (
        <Fragment>
            <Joyride
                steps={steps}
                run={guidance === 'true' ? run : false}
                continuous={true}
                showProgress={false}
                showSkipButton={true}
                callback={handleTourCallback}
                styles={{ options: { primaryColor: '#437eeb' } }}
            />
            <ToastContainer />
            {loading ? (
                <Loader />
            ) : (
                <Row>
                    <Col lg="4" className="">
                        <Elements stripe={stripe} options={appearance}>
                            <PaymentDetails userData={userData} data={data.users[0]} />
                        </Elements>
                    </Col>
                    <Col lg="8">
                        <UserProfile
                            updateUserData={updateUserData}
                            data={data}
                            user={user}
                            validate={guidance === 'true'}
                        />
                    </Col>
                </Row>
            )}
        </Fragment>
    )
})

AdminProfile.displayName = 'AdminProfile'
export default AdminProfile

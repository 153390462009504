import React, { Fragment, useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Link, useLocation } from 'react-router-dom'
import { Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap'
import Card from '../components/common/Card'
import { SettingsContext } from '../context/settings-context'
import { gql } from '@apollo/client'
import { useForm } from '../utils/form-hook'
import { usePasswordValidation } from '../utils/password-validation-hook'
import LogoDark from '../assets/images/logo-text-dark.png'
import LogoLight from '../assets/images/logo-text-light.png'

const RESET_PASSWORD_MUTATION = gql`
    mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
        resetPassword(resetPasswordInput: $resetPasswordInput)
    }
`

const ResetPassword = () => {
    const { theme } = useContext(SettingsContext)
    const [errors, setError] = useState([])
    const [successMessage, setSuccessMessage] = useState('')
    const [passwordUpdated, setPasswordUpdated] = useState(false) // New state variable
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const token = params.get('token')

    const resetPasswordCallback = async () => {
        if (values.password !== values.confirmPassword) {
            setError([{ message: 'Passwords do not match' }])
            return
        }

        try {
            await resetPassword({
                variables: {
                    resetPasswordInput: {
                        token,
                        password: values.password,
                        confirmPassword: values.confirmPassword,
                    },
                },
            })
            setSuccessMessage('Password reset successfully. You can now log in with your new password.')
            setError([])
            setPasswordUpdated(true) // Set the state to true on successful update
        } catch (error) {
            setError([{ message: error.message }])
            setSuccessMessage('')
        }
    }

    const { onChange, onSubmit, values, validated } = useForm(resetPasswordCallback, {
        password: '',
        confirmPassword: '',
    })

    const { passwordValidation } = usePasswordValidation(values)

    const [resetPassword, { loading }] = useMutation(RESET_PASSWORD_MUTATION)

    return (
        <Fragment>
            <section className="iq-auth-page">
                <Row className="d-flex align-items-center justify-content-center vh-100 w-100">
                    <Col md="4" xl="4">
                        <Card className="p-4">
                            <Card.Body>
                                <div className="text-center" style={{ marginBottom: '30px' }}>
                                    <img
                                        src={theme === 'dark' ? LogoDark : LogoLight}
                                        alt="Turba Media"
                                        style={{ height: '45px' }}
                                    />
                                </div>
                                <h3 className="text-center">Reset Password</h3>
                                {!passwordUpdated && (
                                    <>
                                        <p className="text-center">Enter your new password</p>
                                        <Form noValidate validated={validated}>
                                            <Form.Group className="form-group">
                                                <Form.Label>New Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Enter New Password"
                                                    name="password"
                                                    onChange={onChange}
                                                    onKeyUp={passwordValidation}
                                                    value={values.password}
                                                    id="password"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {values.password.length > 0 ? 'Weak Password' : ''}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group className="form-group">
                                                <Form.Label>Confirm New Password</Form.Label>
                                                <Form.Control
                                                    required
                                                    type="password"
                                                    placeholder="Confirm New Password"
                                                    name="confirmPassword"
                                                    onChange={onChange}
                                                    onKeyUp={passwordValidation}
                                                    value={values.confirmPassword}
                                                    id="confirmPassword"
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {values.password !== values.confirmPassword
                                                        ? 'Passwords do not match'
                                                        : ''}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            {errors.length > 0 && (
                                                <div className="pb-3">
                                                    {errors.map((error, key) => (
                                                        <Alert
                                                            key={key}
                                                            variant="warning rounded-0 alert-dismissible fade show"
                                                            role="alert"
                                                        >
                                                            <span>{error.message}</span>
                                                        </Alert>
                                                    ))}
                                                </div>
                                            )}

                                            <div className="text-center pb-3">
                                                <Button
                                                    type="submit"
                                                    variant="btn btn-primary"
                                                    disabled={loading}
                                                    onClick={onSubmit}
                                                >
                                                    {loading ? (
                                                        <Fragment>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />{' '}
                                                            Updating...
                                                        </Fragment>
                                                    ) : (
                                                        'Update Password'
                                                    )}
                                                </Button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                                {successMessage && (
                                    <div className="pt-3">
                                        <Alert variant="success rounded-0 alert-dismissible fade show" role="alert">
                                            <span>{successMessage}</span>
                                        </Alert>
                                    </div>
                                )}
                                {passwordUpdated && (
                                    <div className="text-center pt-3 pb-5">
                                        <Button variant="btn btn-primary" as={Link} to="/login">
                                            Go to Sign In
                                        </Button>
                                    </div>
                                )}
                                <p className="text-center">
                                    Remember your password? <Link to="/login">Sign in</Link>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default ResetPassword

import { useState } from 'react'
import { PhoneNumberUtil } from 'google-libphonenumber'
import _ from 'lodash'

export const usePhoneValidation = (values, canBeEmpty = false) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    const [isPhoneValid, setIsPhoneValid] = useState(true)

    const phoneValidation = () => {
        const inputs = document.getElementsByTagName('input');
        const inputPhone = _.find(inputs, (input) => input.type.toLowerCase() === 'tel')
        const emptyCondition = canBeEmpty ? values.phone.length === 0 : false
        let isValid

        try {
            isValid = emptyCondition || phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(values.phone))
        } catch (error) {
            isValid = emptyCondition || false
        }

        setIsPhoneValid(isValid)

        if (inputPhone) {
            if (isValid) {
                inputPhone.setCustomValidity('')
            } else {
                inputPhone.setCustomValidity('Phone number is invalid')
            }
        }
    }

    return { isPhoneValid, phoneValidation }
}
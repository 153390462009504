import EditLandingPage from '../pages/campaigns/EditLandingPage'
import LandingPageList from '../pages/campaigns/LandingPageList'
import AddLandingPage from '../pages/campaigns/AddLandingPage'

export const LandingPageRoutes =  [
    {
        path: 'landing-page/:id/edit/',
        element: <EditLandingPage />
    },
    {
        path: 'campaigns/:id/landing-page/new/',
        element: <AddLandingPage />
    },
    {
        path: 'campaigns/:id/landing-pages/',
        element: <LandingPageList />
    }
]
import React, { useState, useEffect, useCallback } from 'react'
import { Col, Dropdown } from 'react-bootstrap'
import Chart from 'react-apexcharts'
import { gql, useQuery } from '@apollo/client'
import Card from '../common/Card'
import Loader from '../../layouts/components/Loader'
import { capitaliseFirstLetter } from '../../utils/tools'
import { useChart } from '../../utils/chart-hook'
import CustomToggle from '../../layouts/components/CustomToggle'
import _ from 'lodash'

const GET_SENTIMENT = gql`
    query Campaigns {
        campaigns {
            sentimentThisWeek {
                positive
                neutral
                negative
            }
            sentimentThisMonth {
                positive
                neutral
                negative
            }
            sentimentThisYear {
                positive
                neutral
                negative
            }
        }
    }`

const SentimentAnalysisChart = ({ name }) => {
    const campaign = useQuery(GET_SENTIMENT)
    const [data, setData] = useState(null)

    const {
        series,
        options,
        currentPeriod,
        changePeriod,
        setSeries
    } = useChart('line', data, ['sentimentThisWeek', 'sentimentThisMonth', 'sentimentThisYear'])

    const prepareDataItem = useCallback((data, index) => {
        const names = Object.keys(data[0]).slice(1)
        return {
            name: capitaliseFirstLetter(names[index]),
            data: _.map(data, (item) => item[names[index]])
        }
    }, [])

    const prepareData = useCallback((data) => {
        return _.map([0, 1, 2], (index) => prepareDataItem(data, index))
    }, [prepareDataItem])

    useEffect(() => {
        if (campaign?.data?.campaigns) {
            if (campaign.data.campaigns[0]?.sentimentThisWeek) {
                const sentiment = campaign.data.campaigns[0]
                const value = {
                    sentimentThisWeek: prepareData(sentiment.sentimentThisWeek),
                    sentimentThisMonth: prepareData(sentiment.sentimentThisMonth),
                    sentimentThisYear: prepareData(sentiment.sentimentThisYear)
                }
                setData(value)
                setSeries(value.sentimentThisWeek)
            }
        }
    }, [campaign, prepareData, setSeries])

    return (
        <Col md={12} xl={4}>
            { !data ? (
                <Loader fullScreen={false} />
            ) : (
                <Card data-aos="fade-up" data-aos-delay="1000" className="dashboard-step-8">
                    <div className="flex-wrap card-header d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">{name}</h4>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle as={CustomToggle} href="#" variant=" text-secondary" id="dropdownMenuButton3" aria-expanded="false">
                                {currentPeriod}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="dropdown-menu-end" aria-labelledby="dropdownMenuButton3">
                                <li><Dropdown.Item href="#" onClick={() => changePeriod('week')}>This Week</Dropdown.Item></li>
                                <li><Dropdown.Item href="#" onClick={() => changePeriod('month')}>This Month</Dropdown.Item></li>
                                <li><Dropdown.Item href="#" onClick={() => changePeriod('year')}>This Year</Dropdown.Item></li>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <Card.Body>
                        <Chart className="d-activity" options={options} series={series} type="line" height="300" />
                    </Card.Body>
                </Card>
            )}
        </Col>
    )
}

export default SentimentAnalysisChart
import { ApolloClient, InMemoryCache } from '@apollo/client'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { setContext } from '@apollo/client/link/context'

const authLink = setContext((_, { headers } ) => {
    return {
        headers: {
            ...headers,
            authorization: localStorage.getItem('token') || ''
        }
    }
})

const uploadLink = createUploadLink({
    uri: process.env.REACT_APP_GRAPHQL_URI
})

const client = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache()
})

export default client

import React, { Fragment, useState, useContext } from 'react'
import { useMutation } from '@apollo/client'
import { Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Card from '../components/common/Card'
import { SettingsContext } from '../context/settings-context'
import { gql } from '@apollo/client'
import { useForm } from '../utils/form-hook'
import LogoDark from '../assets/images/logo-text-dark.png'
import LogoLight from '../assets/images/logo-text-light.png'

const FORGOT_PASSWORD_MUTATION = gql`
    mutation ForgotPassword($forgotPasswordInput: ForgotPasswordInput!) {
        forgotPassword(forgotPasswordInput: $forgotPasswordInput)
    }
`

const ForgotPassword = () => {
    const { theme } = useContext(SettingsContext)
    const [errors, setError] = useState([])
    const [successMessage, setSuccessMessage] = useState('')

    const forgotPasswordCallback = async () => {
        try {
            await forgotPassword({ variables: { forgotPasswordInput: { email: values.email } } })
            setSuccessMessage('Password reset email sent. Please check your inbox.')
            setError([])
        } catch (error) {
            setError([{ message: error.message }])
            setSuccessMessage('')
        }
    }

    const { onChange, onSubmit, values, validated } = useForm(forgotPasswordCallback, { email: '' })

    const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD_MUTATION)

    return (
        <Fragment>
            <section className="iq-auth-page">
                <Row className="d-flex align-items-center justify-content-center vh-100 w-100">
                    <Col md="4" xl="4">
                        <Card className="p-4">
                            <Card.Body>
                                <div className="text-center" style={{ marginBottom: '30px' }}>
                                    <img
                                        src={theme === 'dark' ? LogoDark : LogoLight}
                                        alt="Turba Media"
                                        style={{ height: '45px' }}
                                    />
                                </div>
                                <h3 className="text-center">Forgot Password</h3>
                                <p className="text-center">Enter your email to reset your password</p>
                                <Form noValidate validated={validated}>
                                    <Form.Group className="form-group">
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Enter Email"
                                            name="email"
                                            onChange={onChange}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please enter a valid Email
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {errors.length > 0 && (
                                        <div className="pb-3">
                                            {errors.map((error, key) => (
                                                <Alert
                                                    key={key}
                                                    variant="warning rounded-0 alert-dismissible fade show"
                                                    role="alert"
                                                >
                                                    <span>{error.message}</span>
                                                </Alert>
                                            ))}
                                        </div>
                                    )}
                                    {successMessage && (
                                        <div className="pb-3">
                                            <Alert variant="success rounded-0 alert-dismissible fade show" role="alert">
                                                <span>{successMessage}</span>
                                            </Alert>
                                        </div>
                                    )}
                                    <div className="text-center pb-3">
                                        <Button
                                            type="submit"
                                            variant="btn btn-primary"
                                            disabled={loading}
                                            onClick={onSubmit}
                                        >
                                            {loading ? (
                                                <Fragment>
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />{' '}
                                                    Sending...
                                                </Fragment>
                                            ) : (
                                                'Send Reset Link'
                                            )}
                                        </Button>
                                    </div>
                                </Form>
                                <p className="text-center">
                                    Remember your password? <Link to="/login">Sign in</Link>
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
}

export default ForgotPassword

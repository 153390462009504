import React from 'react'
import { Form } from 'react-bootstrap'

const LandingPageTextField = ({ name, label, onChange, values, cols, required= false, textarea = false }) => {
    return (
        <Form.Group className={cols ? `col-md-${cols} form-group` : "col-md-6 form-group"}>
            <Form.Label htmlFor={name}>{label}</Form.Label>
            { textarea ? (
                    <Form.Control required={required} as="textarea" id={name} placeholder={'Enter ' + label} name={name} value={values[name]} onChange={onChange}></Form.Control>
                ) : (
                    <Form.Control type="text" id={name} placeholder={'Enter ' + label} name={name} value={values[name]} onChange={onChange}></Form.Control>
                )
            }
        </Form.Group>
    );
}

export default  LandingPageTextField
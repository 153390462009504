import React, {useContext} from 'react'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import { SettingsContext } from '../../context/settings-context'
import { getPhoneFormInputStyle } from '../../utils/theme-settings'

const PhoneFormInput = ({ value, setValue, validated = false, isValid = true }) => {
    const { theme } = useContext(SettingsContext)

    const onChangePhone = (phone) => {
        setValue((prevState) => {
            return {
                ...prevState,
                phone: phone
            }
        })
    }

    return (
        <PhoneInput
            defaultCountry="au"
            placeholder="Enter Phone Number"
            value={value}
            style={getPhoneFormInputStyle(theme, validated, isValid)}
            inputStyle={{width: '100%'}}
            onChange={(phone) => onChangePhone(phone)}
        />
    )
}

export default PhoneFormInput
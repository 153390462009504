import React, { memo, Fragment, useEffect, useState, useContext, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { AuthContext } from '../../context/auth-context'
import { GuidanceContext } from '../../context/guidance-contex'
import { CORE_CAMPAIGN_FIELDS } from '../../graphql/campaign-fragments'
import { Row, Col } from 'react-bootstrap'
import Card from '../../components/common/Card'
import DataTable from '../../components/common/DataTable'
import Notification from '../../components/partials/Notification'
import { showNotification } from '../../utils/notifications'
import Loader from '../../layouts/components/Loader'
import _ from 'lodash'

const GET_CAMPAIGNS = gql`
    ${CORE_CAMPAIGN_FIELDS}
    query Campaigns {
        campaigns {
            ...CoreCampaignFields
        }
    }`

const socialIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path opacity="0.4" d="M12.02 2C6.21 2 2 6.74 2 12C2 13.68 2.49 15.41 3.35 16.99C3.51 17.25 3.53 17.58 3.42 17.89L2.75 20.13C2.6 20.67 3.06 21.07 3.57 20.91L5.59 20.31C6.14 20.13 6.57 20.36 7.081 20.67C8.541 21.53 10.36 21.97 12 21.97C16.96 21.97 22 18.14 22 11.97C22 6.65 17.7 2 12.02 2Z" fill="currentColor"></path>
    <path fillRule="evenodd" clipRule="evenodd" d="M11.9807 13.2901C11.2707 13.2801 10.7007 12.7101 10.7007 12.0001C10.7007 11.3001 11.2807 10.7201 11.9807 10.7301C12.6907 10.7301 13.2607 11.3001 13.2607 12.0101C13.2607 12.7101 12.6907 13.2901 11.9807 13.2901ZM7.37033 13.2901C6.67033 13.2901 6.09033 12.7101 6.09033 12.0101C6.09033 11.3001 6.66033 10.7301 7.37033 10.7301C8.08033 10.7301 8.65033 11.3001 8.65033 12.0101C8.65033 12.7101 8.08033 13.2801 7.37033 13.2901ZM15.3105 12.0101C15.3105 12.7101 15.8805 13.2901 16.5905 13.2901C17.3005 13.2901 17.8705 12.7101 17.8705 12.0101C17.8705 11.3001 17.3005 10.7301 16.5905 10.7301C15.8805 10.7301 15.3105 11.3001 15.3105 12.0101Z" fill="currentColor"></path>
    </svg>`

const editIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path opacity="0.4" d="M16.6643 21.9897H7.33488C5.88835 22.0796 4.46781 21.5781 3.3989 20.6011C2.4219 19.5312 1.92041 18.1107 2.01032 16.6652V7.33482C1.92041 5.88932 2.4209 4.46878 3.3979 3.39889C4.46781 2.42189 5.88835 1.92041 7.33488 2.01032H16.6643C18.1089 1.92041 19.5284 2.4209 20.5973 3.39789C21.5733 4.46878 22.0758 5.88832 21.9899 7.33482V16.6652C22.0788 18.1107 21.5783 19.5312 20.6013 20.6011C19.5314 21.5781 18.1109 22.0796 16.6643 21.9897Z" fill="currentColor"></path>
    <path d="M17.0545 10.3976L10.5018 16.9829C10.161 17.3146 9.7131 17.5 9.24574 17.5H6.95762C6.83105 17.5 6.71421 17.4512 6.62658 17.3634C6.53895 17.2756 6.5 17.1585 6.5 17.0317L6.55842 14.7195C6.56816 14.261 6.75315 13.8317 7.07446 13.5098L11.7189 8.8561C11.7967 8.77805 11.9331 8.77805 12.011 8.8561L13.6399 10.4785C13.747 10.5849 13.9028 10.6541 14.0683 10.6541C14.4286 10.6541 14.7109 10.3615 14.7109 10.0102C14.7109 9.83463 14.6428 9.67854 14.5357 9.56146C14.5065 9.52244 12.9554 7.97805 12.9554 7.97805C12.858 7.88049 12.858 7.71463 12.9554 7.61707L13.6078 6.95366C14.2114 6.34878 15.1851 6.34878 15.7888 6.95366L17.0545 8.22195C17.6485 8.81707 17.6485 9.79268 17.0545 10.3976Z" fill="currentColor"></path>
    </svg>`

const settingsIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-32" height="32">
    <path d="M12.0122 14.8299C10.4077 14.8299 9.10986 13.5799 9.10986 12.0099C9.10986 10.4399 10.4077 9.17993 12.0122 9.17993C13.6167 9.17993 14.8839 10.4399 14.8839 12.0099C14.8839 13.5799 13.6167 14.8299 12.0122 14.8299Z" fill="currentColor"></path>
    <path opacity="0.4" d="M21.2301 14.37C21.036 14.07 20.76 13.77 20.4023 13.58C20.1162 13.44 19.9322 13.21 19.7687 12.94C19.2475 12.08 19.5541 10.95 20.4228 10.44C21.4447 9.87 21.7718 8.6 21.179 7.61L20.4943 6.43C19.9118 5.44 18.6344 5.09 17.6226 5.67C16.7233 6.15 15.5685 5.83 15.0473 4.98C14.8838 4.7 14.7918 4.4 14.8122 4.1C14.8429 3.71 14.7203 3.34 14.5363 3.04C14.1582 2.42 13.4735 2 12.7172 2H11.2763C10.5302 2.02 9.84553 2.42 9.4674 3.04C9.27323 3.34 9.16081 3.71 9.18125 4.1C9.20169 4.4 9.10972 4.7 8.9462 4.98C8.425 5.83 7.27019 6.15 6.38109 5.67C5.35913 5.09 4.09191 5.44 3.49917 6.43L2.81446 7.61C2.23194 8.6 2.55897 9.87 3.57071 10.44C4.43937 10.95 4.74596 12.08 4.23498 12.94C4.06125 13.21 3.87729 13.44 3.59115 13.58C3.24368 13.77 2.93709 14.07 2.77358 14.37C2.39546 14.99 2.4159 15.77 2.79402 16.42L3.49917 17.62C3.87729 18.26 4.58245 18.66 5.31825 18.66C5.66572 18.66 6.0745 18.56 6.40153 18.36C6.65702 18.19 6.96361 18.13 7.30085 18.13C8.31259 18.13 9.16081 18.96 9.18125 19.95C9.18125 21.1 10.1215 22 11.3069 22H12.6968C13.872 22 14.8122 21.1 14.8122 19.95C14.8429 18.96 15.6911 18.13 16.7029 18.13C17.0299 18.13 17.3365 18.19 17.6022 18.36C17.9292 18.56 18.3278 18.66 18.6855 18.66C19.411 18.66 20.1162 18.26 20.4943 17.62L21.2097 16.42C21.5776 15.75 21.6083 14.99 21.2301 14.37Z" fill="currentColor"></path>
    </svg>`

const mediaBuyingIcon = `<svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z" fill="currentColor"></path>
    <path d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z" fill="currentColor"></path>
    </svg>`

const columns = [
    {
        title: 'Name',
        data: 'name'
    },
    {
        title: 'Status',
        data: 'status',
        render: (data) => {
            return (
                '<span class="badge ' + data.color + '">' + data.status + '</span>'
            )
        }
    },
    {
        title: 'Created',
        data: 'date'
    },
    {
        title: 'Manager Username',
        data: 'username'
    },
    {
        title: 'Manager Name',
        data: 'fullName'
    },
    {
        title: 'Manager Email',
        data: 'email'
    },
    {
        title: 'Action',
        data: 'id',
        render: (data) => {
            return (
                '<div class="flex align-items-center list-campaign-action"><a class="btn btn-icon btn-primary social-icon opacity-10 tooltip" data-target="' + data + '"><span class="btn-inner">' +
                socialIcon +
                '</span><span class="tooltiptext">Platform Settings</span></a> <a class="btn btn-icon btn-primary edit-icon opacity-8 tooltip" data-target="' + data + '"><span class="btn-inner">' +
                editIcon +
                '</span><span class="tooltiptext">Edit Campaign</span></a> <a class="btn btn-icon btn-primary settings-icon opacity-6 tooltip" data-target="' + data + '"><span class="btn-inner">' +
                settingsIcon +
                '</span><span class="tooltiptext">Creatives</span></a> <a class="btn btn-icon btn-primary media-buying-icon opacity-4 tooltip" data-target="' + data + '"><span class="btn-inner">' +
                mediaBuyingIcon +
                '</span><span class="tooltiptext">Media Buying</span></a></div>'
            )
        }
    }
]

const CampaignList = memo(() => {
    const { data, loading, refetch } = useQuery(GET_CAMPAIGNS)
    const [campaignData, setCampaignData] = useState([])
    const navigate = useNavigate()
    const location = useLocation()
    const { user } = useContext(AuthContext)
    const { guidance } = useContext(GuidanceContext)

    const guidedRef = useRef(false)

    const getCampaignStatus = (campaign) => {
        if (campaign.userStatus === 'ACTIVE') {
            if (campaign.systemStatus === 'ACTIVE') {
                return { status: 'Active', color: 'bg-primary' }
            } else {
                if (campaign.systemStatus === 'PENDING') {
                    return { status: 'Pending', color: 'bg-warning' }
                } else {
                    return { status: 'Suspended', color: 'bg-info' }
                }
            }
        } else {
            return { status: 'Inactive', color: 'bg-danger' }
        }
    }

    useEffect(() => {
        const array = []
        if (data) {
            _.each(data.campaigns, (item) => {
                const { status, color } = getCampaignStatus(item)

                if (item.user) {
                    array.push({
                        id: item.id,
                        name: item.name,
                        status: {
                            status: status,
                            color: color
                        },
                        date: new Date(Date.parse(item.createdAt)).toLocaleString('en-GB'),
                        username: item.user.username,
                        fullName: `${item.user.firstName} ${item.user.lastName}`,
                        email: item.user.email ? item.user.email : user?.email
                    })
                }
            })

            if (array) {
                setCampaignData(array)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const onClickEditButton = (event) => {
        const campaignId = event.currentTarget.getAttribute('data-target')
        navigate('/campaigns/edit/' + campaignId)
    }

    const onClickPlatformsButton = (event) => {
        const campaignId = event.currentTarget.getAttribute('data-target')
        navigate('/campaigns/platforms/' + campaignId)
    }

    const onClickLandingPageButton = (event) => {
      const campaignId = event.currentTarget.getAttribute('data-target')
      navigate(`/campaigns/${campaignId}/landing-pages/`)
    }

    const onClickMediaBuyingButton = (event) => {
        const campaignId = event.currentTarget.getAttribute('data-target')
        navigate(`/campaigns/${campaignId}/media-buying/`)
    }

    const bindButtonEvents = () => {
        const editButtons = document.getElementsByClassName('edit-icon')
        const platformButtons = document.getElementsByClassName('social-icon')
        const landingPageButtons = document.getElementsByClassName('settings-icon')
        const mediaBuyingButtons = document.getElementsByClassName('media-buying-icon')

        _.each(editButtons, (element) => {
            element.removeEventListener('click', onClickEditButton)
            element.addEventListener('click', onClickEditButton)
        })
        _.each(platformButtons, (element) => {
            element.removeEventListener('click', onClickPlatformsButton)
            element.addEventListener('click', onClickPlatformsButton)
        })
        _.each(landingPageButtons, (element) => {
            element.removeEventListener('click', onClickLandingPageButton)
            element.addEventListener('click', onClickLandingPageButton)
        })
        _.each(mediaBuyingButtons, (element) => {
            element.removeEventListener('click', onClickMediaBuyingButton)
            element.addEventListener('click', onClickMediaBuyingButton)
        })
    }

    useEffect(() => {
        if (location.state?.refetch) {
            refetch()
        }
    }, [location.state?.refetch, refetch])

    useEffect(() => {
        if (guidance === 'true' && !guidedRef.current) {
            showNotification('Click on the "Platform Settings" icon (first icon in the "Action" column) to start connecting to Platforms', 'info', false)
            guidedRef.current = true
        }
    }, [guidance])

    return (
        <Fragment>
            { loading ? (
                <Loader />
            ) : (
                <Row>
                    <Col sm="12">
                        <Card>
                            <Card.Header className="d-flex justify-content-between">
                                <div className="header-title">
                                    <h4 className="card-title">
                                        <i className="icon">
                                            <svg width="25" className="icon-25" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.4" d="M16.191 2H7.81C4.77 2 3 3.78 3 6.83V17.16C3 20.26 4.77 22 7.81 22H16.191C19.28 22 21 20.26 21 17.16V6.83C21 3.78 19.28 2 16.191 2" fill="currentColor"></path>
                                                <path fillRule="evenodd" clipRule="evenodd" d="M8.07999 6.64999V6.65999C7.64899 6.65999 7.29999 7.00999 7.29999 7.43999C7.29999 7.86999 7.64899 8.21999 8.07999 8.21999H11.069C11.5 8.21999 11.85 7.86999 11.85 7.42899C11.85 6.99999 11.5 6.64999 11.069 6.64999H8.07999ZM15.92 12.74H8.07999C7.64899 12.74 7.29999 12.39 7.29999 11.96C7.29999 11.53 7.64899 11.179 8.07999 11.179H15.92C16.35 11.179 16.7 11.53 16.7 11.96C16.7 12.39 16.35 12.74 15.92 12.74ZM15.92 17.31H8.07999C7.77999 17.35 7.48999 17.2 7.32999 16.95C7.16999 16.69 7.16999 16.36 7.32999 16.11C7.48999 15.85 7.77999 15.71 8.07999 15.74H15.92C16.319 15.78 16.62 16.12 16.62 16.53C16.62 16.929 16.319 17.27 15.92 17.31Z" fill="currentColor"></path>
                                            </svg>
                                        </i>{' '}
                                        Campaign List
                                    </h4>
                                </div>
                            </Card.Header>
                            <Card.Body className="px-0">
                                <div className="table-responsive">
                                    <DataTable data={campaignData} columns={columns} onDraw={bindButtonEvents}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            )}
            <Notification/>
        </Fragment>
    )
})

CampaignList.displayName = 'CampaignList'
export default CampaignList

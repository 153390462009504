import React from 'react'
import { Form } from 'react-bootstrap'


const LandingPageFormField = ({ number, onChange, values }) => {
    const isDropDownSelected = () => {
        return values['field' + number + 'Type'] === 'dropdown'
    }

    return (
        <div className="row">
            <Form.Group className={isDropDownSelected() ? 'col-md-3 form-group' : 'col-md-5 form-group'}>
                <Form.Control type="text" id={`label${number}`} placeholder={'Enter label ' + number} name={`field${number}Label`} value={values[`field${number}Label`]} onChange={onChange} required={number < 4}></Form.Control>
            </Form.Group>
            {isDropDownSelected() && (
                <Form.Group className="col-md-3 form-group">
                    <Form.Control type="text" id={`values${number}`} placeholder="Enter comma separated values" name={`field${number}Values`} value={values[`field${number}Values`]} onChange={onChange} required></Form.Control>
                </Form.Group>
            )}
            <Form.Group className="col-md-2 form-group">
                <Form.Select type="text" id={`required${number}`} name={`field${number}Required`} value={values[`field${number}Required`]} onChange={onChange}>
                    <option value="not_required">Not required</option>
                    <option value="required">Required</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className="col-md-2 form-group">
                <Form.Select type="text" id={`type${number}`} name={`field${number}Type`} value={values[`field${number}Type`]} onChange={onChange}>
                    <option value="text_field">Text Field</option>
                    <option value="text_area">Text Area</option>
                    <option value="dropdown">Dropdown</option>
                    <option value="checkbox">Checkbox</option>
                    <option value="date_picker">DatePicker</option>
                </Form.Select>
            </Form.Group>
            <Form.Group className={isDropDownSelected() ? 'col-md-2 form-group' : 'col-md-3 form-group'}>
                <Form.Select type="text" id={`enabled${number}`} name={`field${number}Enabled`} value={values[`field${number}Enabled`]} onChange={onChange} disabled={number < 4}>
                    <option value="disabled">Disabled</option>
                    <option value="enabled">Enabled</option>
                </Form.Select>
            </Form.Group>
        </div>
    )
}

export default LandingPageFormField
import React, { useEffect, Fragment, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button, Spinner, FormCheck, Col, Image } from 'react-bootstrap'
import Joyride, { STATUS } from 'react-joyride'
import { GuidanceContext } from '../../context/guidance-contex'
import { useForm } from '../../utils/form-hook'
import ShowErrors from '../../components/partials/ShowErrors'
import LandingPageFormField from './LandingPageFormField'
import LandingPageTextField from './LandingPageTextField'
import { PopoverPicker } from '../common/ColourPicker'
import Tooltip from '../common/Tooltip'
import _ from 'lodash'

const initLandingPageFormData = () => {
    const data = {
        title: '',
        domain: '',
        theme: '',
        metaKeywords: '',
        metaDescription: '',
        customHtmlHeader: '',
        status: 'ACTIVE',
        email: '',
        hasForm: false,
        formButtonSettings: '',
        buttonLabel: '',
        buttonColour: '',
        formFields: '',
        pageSettings: '',
        pageHeading: '',
        pageHeadingColor: '',
        pageHeadingFont: '',
        pageSubHeading: '',
        pageSubHeadingColor: '',
        pageSubHeadingFont: '',
        pagePopupSettings: '',
        popupTitle: '',
        popupDescription: '',
        popupButtonLabel: '',
        popupButtonUrl: '',
        popupButtonColour: '',
        formContent: '',
        logo: '',
        logoImage: '',
        background: 'image',
        backgroundImage: '',
        backgroundVideo: '',
        termsAndConditions: '',
    }

    _.each(_.range(1, 9), (number) => {
        data[`field${number}Label`] = ''
        data[`field${number}Values`] = ''
        data[`field${number}Required`] = 'not_required'
        data[`field${number}Type`] = 'text_field'
        data[`field${number}Enabled`] = number < 4 ? 'enabled' : 'disabled'
    })

    return data
}

const LandingPageForm = ({ data, updateData, callback, errors, mutation, themes, campaignId }) => {
    const navigate = useNavigate()
    const [addTermsAndConditions, setAddTermsAndConditions] = useState(false)
    const { guidance } = useContext(GuidanceContext)

    const [run, setRun] = useState(true)
    const [steps] = useState([
        {
            target: '.lp-step-1',
            content: 'Enter a descriptive name of the Landing Page to distinguish it from others',
        },
        {
            target: '.lp-step-2',
            content:
                'Enter a subdomain name for your landing page. Note that the full URL of your landing page will be [subdomain].pages.turbamedia.io. Subdomains must be unique across the entire system, not just for your landing pages',
        },
        {
            target: '.lp-step-3',
            content: 'Select the theme for your landing page',
        },
        {
            target: '.lp-step-4',
            content: 'Enter your email (optional)',
        },
        {
            target: '.lp-step-5',
            content:
                'Enter meta keywords for your landing page to improve its visibility on search engines, such as Google (optional)',
        },
        {
            target: '.lp-step-6',
            content:
                'Enter meta description for your landing page to improve its visibility on search engines, such as Google (optional)',
        },
        {
            target: '.lp-step-7',
            content: 'Add custom HTML Header (optional)',
        },
        {
            target: '.lp-step-8',
            content:
                'Define the form fields to collect data from your customers. You can add text fields, text areas, dropdowns, checkboxes, or date pickers. Enable as many fields as you need (note that the first three fields are always enabled). Enter labels for the fields and specify if they are required or optional',
        },
        {
            target: '.lp-step-9',
            content: 'Name the label and colour for the submit button on your form',
        },
        {
            target: '.lp-step-10',
            content: 'To add headlines, enter the Heading (main headline) and the Sub Heading (secondary headline). You can also change colour and font.',
        },
        {
            target: '.lp-step-11',
            content:
                'Set up the Page Popup that will automatically open 5 seconds after users arrive on the page or by clicking a plus button. Enter the title (headline) and description (main text) for your popup, specify the button label, optionally add a URL for the button link, and choose a color for the popup elements',
        },
        {
            target: '.lp-step-12',
            content: 'Enter the Form Content that you want to display on the Landing Page',
        },
        {
            target: '.lp-step-13',
            content:
                'Enter the text for your logo and upload an image of your logo to be displayed on the Landing Page',
        },
        {
            target: '.lp-step-14',
            content: 'Upload an image or video for the background of the landing page',
        },
        {
            target: '.lp-step-15',
            content:
                'Set the status of your Landing Page to active or inactive. Only active Landing Pages are published on the Internet and can generate leads',
        },
        {
            target: '.lp-step-16',
            content:
                'If you want to add Terms and Conditions to the Landing Page, check the checkbox and add the URL to your Terms and Conditions',
        },
        {
            target: '.lp-step-17',
            content: 'Click the "Create" button to generate a Landing Page with the specified parameters',
        },
        {
            target: '.lp-step-18',
            content: 'Set the headline color, font family, and background video URL for the Landing Page',
        },
    ])

    const fonts = [
        { name: 'Arial', css: 'Arial, Helvetica, sans-serif' },
        { name: 'Helvetica', css: 'Helvetica, Arial, sans-serif' },
        { name: 'Verdana', css: 'Verdana, Geneva, sans-serif' },
        { name: 'Times New Roman', css: '"Times New Roman", Times, serif' },
        { name: 'Georgia', css: 'Georgia, serif' },
        { name: 'Courier New', css: '"Courier New", Courier, monospace' },
        { name: 'Lucida Console', css: '"Lucida Console", Monaco, monospace' },
        { name: 'Comic Sans MS', css: '"Comic Sans MS", cursive, sans-serif' }
    ]

    const handleTourCallback = (data) => {
        const { status } = data
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false)
        }
    }

    const saveButtonName = data ? ['Update', 'Updating'] : ['Create', 'Creating']

    const { onChange, onFileChange, onSubmit, values, validated, setValue } = useForm(
        callback,
        initLandingPageFormData()
    )

    const toggleTermsAndConditions = () => {
        setAddTermsAndConditions((prevState) => {
            if (prevState) {
                setValue((prevValue) => {
                    return { ...prevValue, termsAndConditions: '' }
                })
            }
            return !prevState
        })
    }

    const toggleHasForm = () => {
        setValue((prevState) => {
            return { ...values, hasForm: !prevState.hasForm }
        })
    }

    const setColor = (color, value) => {
        setValue((prevState) => {
            return { ...prevState, [value]: color }
        })
    }

    useEffect(() => {
        if (!_.isEmpty(data) && !values.domain) {
            _.each(values, (value, key) => {
                switch (key) {
                    case 'keywords':
                        setValue((prevState) => {
                            return { ...prevState, [key]: JSON.parse(data[key]) }
                        })
                        break
                    case 'theme':
                        setValue((prevState) => {
                            return { ...prevState, theme: data.theme.id }
                        })
                        break
                    case 'formFields':
                        setValue((prevState) => {
                            const formFields = JSON.parse(data.formFields)
                            const formData = {}
                            for (let index = 0; index < formFields.length; index++) {
                                if (formFields[index].label !== '')
                                    formData[`field${index + 1}Label`] = formFields[index].label
                                if (formFields[index].required !== '')
                                    formData[`field${index + 1}Required`] = formFields[index].required
                                if (formFields[index].type !== '')
                                    formData[`field${index + 1}Type`] = formFields[index].type
                                if (formFields[index].enabled !== '')
                                    formData[`field${index + 1}Enabled`] = formFields[index].enabled
                                if (formFields[index].values !== '')
                                    formData[`field${index + 1}Values`] = formFields[index].values
                            }
                            return { ...prevState, ...formData }
                        })
                        break
                    case 'formButtonSettings':
                        if (data.formButtonSettings) {
                            setValue((prevState) => {
                                const buttonSettings = JSON.parse(data.formButtonSettings)
                                return { ...prevState, ...buttonSettings }
                            })
                        }
                        break
                    case 'pageSettings':
                        if (data.pageSettings) {
                            setValue((prevState) => {
                                const pageSettings = JSON.parse(data.pageSettings)
                                return { ...prevState, ...pageSettings }
                            })
                        }
                        break
                    case 'pagePopupSettings':
                        if (data.pagePopupSettings) {
                            setValue((prevState) => {
                                const pagePopupSettings = JSON.parse(data.pagePopupSettings)
                                return { ...prevState, ...pagePopupSettings }
                            })
                        }
                        break
                    case 'logoImage':
                    case 'backgroundImage':
                    case 'backgroundVideo':
                        break
                    default:
                        if (data[key]) {
                            setValue((prevState) => {
                                return { ...prevState, [key]: data[key] ? data[key] : '' }
                            })
                            if (key === 'termsAndConditions') {
                                setAddTermsAndConditions(true)
                            }
                        }
                }
            })
        }
    }, [data, setValue, values])

    useEffect(() => {
        updateData(values)
    }, [values, updateData])

    return (
        <Fragment>
            <Joyride
                steps={steps}
                run={guidance === 'true' ? run : false}
                continuous={true}
                showProgress={false}
                showSkipButton={true}
                callback={handleTourCallback}
                styles={{ options: { primaryColor: '#437eeb' } }}
            />
            <Form noValidate validated={validated}>
                <h5 className="mb-3">Landing Page Details</h5>
                <div className="row">
                    <Form.Group className="col-md-6 form-group lp-step-1">
                        <Form.Label htmlFor="domain">
                            Title
                            <Tooltip text={'Enter a descriptive name of Landing Page to distinguish it from others'}/>
                        </Form.Label>
                        <Form.Control
                            required
                            type="text"
                            id="title"
                            placeholder="Enter title"
                            name="title"
                            value={values.title}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-6 form-group lp-step-2">
                        <Form.Label htmlFor="domain">
                            Domain
                            <Tooltip text={'Enter a unique subdomain name of the Landing Page'}/>
                        </Form.Label>
                        <div style={{display: 'flex'}}>
                            <Form.Control
                                required
                                type="text"
                                id="domain"
                                placeholder="Enter domain"
                                name="domain"
                                value={values.domain}
                                onChange={onChange}
                                style={{width: '50%'}}
                            ></Form.Control>
                            <div style={{marginLeft: '5px', marginTop: '10px'}}>.pages.turbamedia.io</div>
                        </div>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group lp-step-3">
                        <Form.Label htmlFor="theme">Theme</Form.Label>
                        <Form.Select required id="theme" name="theme" value={values.theme} onChange={onChange}>
                            <option disabled value="">
                                Select Theme
                            </option>
                            {themes.data?.themes.map((theme) => (
                                <option key={theme.id} value={theme.id}>
                                    {theme.name}
                                </option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-6 form-group lp-step-4">
                        <Form.Label htmlFor="email">Email</Form.Label>
                        <Form.Control
                            type="text"
                            id="email"
                            placeholder="Enter email"
                            name="email"
                            value={values.email}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                    <Form.Group className="col-md-6 form-group lp-step-5">
                        <Form.Label htmlFor="metaKeywords">Meta Keywords</Form.Label>
                        <Form.Control
                            type="text"
                            id="metaKeywords"
                            placeholder="Enter meta keywords"
                            name="metaKeywords"
                            value={values.metaKeywords}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-12 form-group lp-step-6">
                        <Form.Label htmlFor="metaDescription">Meta Description</Form.Label>
                        <Form.Control
                            as="textarea"
                            id="metaDescription"
                            placeholder="Enter meta description"
                            name="metaDescription"
                            value={values.metaDescription}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                </div>
                <div className="row">
                    <Form.Group className="col-md-12 form-group lp-step-7">
                        <Form.Label htmlFor="customHtmlHeader">Custom HTML Header</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={4}
                            id="customHtmlHeader"
                            placeholder="Enter custom HTML header"
                            name="customHtmlHeader"
                            value={values.customHtmlHeader}
                            onChange={onChange}
                        ></Form.Control>
                    </Form.Group>
                </div>
                <hr/>
                <h5 className="mb-3">Page Settings</h5>
                <div className="row lp-step-10">
                    <LandingPageTextField
                        name="pageHeading"
                        label="Heading"
                        onChange={onChange}
                        values={values}
                        textarea={true}
                    ></LandingPageTextField>
                    <Form.Group className="col-md-3 form-group">
                        <Form.Label htmlFor="pageHeadingColor">Heading Colour</Form.Label>
                        <div className="color-picker">
                            <PopoverPicker
                                color={values.pageHeadingColor}
                                onChange={(color) => setColor(color, 'pageHeadingColor')}
                            />
                            <Form.Control
                                type="text"
                                id="pageHeadingColor"
                                placeholder="Enter Heading Colour"
                                name="pageHeadingColor"
                                value={values.pageHeadingColor}
                                onChange={onChange}
                            ></Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group className="col-md-3 form-group">
                        <Form.Label htmlFor="pageHeadingFont">Heading Font</Form.Label>
                        <Form.Select type="text" id="pageHeadingFont" name="pageHeadingFont"
                                     value={values['pageHeadingFont']} onChange={onChange}>
                            <option value="">Default</option>
                            {fonts.map((font) => (
                                <option key={font.name} value={font.css}>{font.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <LandingPageTextField
                        name="pageSubHeading"
                        label="Sub Heading"
                        onChange={onChange}
                        values={values}
                        textarea={true}
                    ></LandingPageTextField>
                    <Form.Group className="col-md-3 form-group">
                        <Form.Label htmlFor="pageSubHeadingColor">Sub Heading Colour</Form.Label>
                        <div className="color-picker">
                            <PopoverPicker
                                color={values.pageSubHeadingColor}
                                onChange={(color) => setColor(color, 'pageSubHeadingColor')}
                            />
                            <Form.Control
                                type="text"
                                id="pageSubHeadingColor"
                                placeholder="Enter Sub Heading Colour"
                                name="pageSubHeadingColor"
                                value={values.pageSubHeadingColor}
                                onChange={onChange}
                            ></Form.Control>
                        </div>
                    </Form.Group>
                    <Form.Group className="col-md-3 form-group">
                        <Form.Label htmlFor="pageSubHeadingFont">Sub Heading Font</Form.Label>
                        <Form.Select type="text" id="pageSubHeadingFont" name="pageSubHeadingFont"
                                     value={values['pageSubHeadingFont']} onChange={onChange}>
                            <option value="">Default</option>
                            {fonts.map((font) => (
                                <option key={font.name} value={font.css}>{font.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                </div>

                <hr/>
                <h5 className="mb-3">Page Popup Settings</h5>
                <div className="row lp-step-11">
                    <LandingPageTextField
                        name="popupTitle"
                        label="Title"
                        onChange={onChange}
                        values={values}
                    ></LandingPageTextField>
                    <LandingPageTextField
                        name="popupDescription"
                        label="Description"
                        onChange={onChange}
                        values={values}
                    ></LandingPageTextField>
                    <LandingPageTextField
                        name="popupButtonLabel"
                        label="Button Label"
                        onChange={onChange}
                        values={values}
                    ></LandingPageTextField>
                    <LandingPageTextField
                        name="popupButtonUrl"
                        label="Button Url"
                        onChange={onChange}
                        values={values}
                    ></LandingPageTextField>
                    <Form.Group className="col-md-6 form-group">
                        <Form.Label htmlFor="popupButtonColour">Button Color</Form.Label>
                        <div className="color-picker">
                            <PopoverPicker
                                color={values.popupButtonColour}
                                onChange={(color) => setColor(color, 'popupButtonColour')}
                            />
                            <Form.Control
                                type="text"
                                id="popupButtonColour"
                                placeholder="Enter Popup Button Color"
                                name="popupButtonColour"
                                value={values.popupButtonColour}
                                onChange={onChange}
                            ></Form.Control>
                        </div>
                    </Form.Group>
                </div>
                <hr />
                <h5 className="mb-3">Form</h5>
                <Form.Check className="form-check-inline">
                    <FormCheck.Input
                        type="checkbox"
                        className="form-check-input"
                        id="enableForm"
                        checked={values.hasForm}
                        onChange={toggleHasForm}
                    />
                    <FormCheck.Label className="form-check-label pl-2" htmlFor="enableForm">
                        Add Form to the Page?
                    </FormCheck.Label>
                </Form.Check>
                <hr/>
                {values.hasForm && (
                    <Fragment>
                        <hr/>
                        <h5 className="mb-3">
                            Form Labels
                            <Tooltip text={'Define the form fields to collect data from your customers'}/>
                        </h5>
                        <div className="row lp-step-8">
                            <Form.Group className="col-md-12 form-group">
                                {_.range(1, 9).map((number) => (
                                    <LandingPageFormField
                                        key={number}
                                        number={number}
                                        values={values}
                                        onChange={onChange}
                                    />
                                ))}
                            </Form.Group>
                        </div>
                        <hr/>
                        <h5 className="mb-3">Form Button Settings</h5>
                        <div className="row lp-step-9">
                            <LandingPageTextField
                                name="buttonLabel"
                                label="Label"
                                onChange={onChange}
                                values={values}
                            ></LandingPageTextField>
                            <Form.Group className="col-md-6 form-group">
                                <Form.Label htmlFor="buttonColour">Color</Form.Label>
                                <div className="color-picker">
                                    <PopoverPicker
                                        color={values.buttonColour}
                                        onChange={(color) => setColor(color, 'buttonColour')}
                                    />
                                    <Form.Control
                                        type="text"
                                        id="buttonColour"
                                        placeholder="Enter Button Color"
                                        name="buttonColour"
                                        value={values.buttonColour}
                                        onChange={onChange}
                                    ></Form.Control>
                                </div>
                            </Form.Group>
                        </div>
                        <hr/>
                        <h5 className="mb-3">Form Content</h5>
                        <div className="row lp-step-12">
                            <LandingPageTextField
                                name="formContent"
                                label="Content"
                                onChange={onChange}
                                values={values}
                                cols="12"
                                textarea={true}
                            ></LandingPageTextField>
                        </div>
                    </Fragment>
                )}
                <hr/>
                <h5 className="mb-3">Logo Settings</h5>
                <div className="row lp-step-13">
                    <LandingPageTextField
                        name="logo"
                        label="Logo Text"
                        onChange={onChange}
                        values={values}
                    ></LandingPageTextField>
                    <Col md={data?.logoImage ? 4 : 6} xs={12}>
                        <Form.Group className="form-group">
                            <Form.Label className="custom-file-input">Update Logo</Form.Label>
                            <Form.Control
                                type="file"
                                accept=".jpg,.gif,.png"
                                id="logo-image"
                                name="logoImage"
                                onChange={onFileChange}
                            />
                        </Form.Group>
                    </Col>
                    {data?.logoImage && (
                        <Col xs={2} className="landing-page-image-container">
                            <Image src={process.env.REACT_APP_S3_BUCKET_URL + data.logoImage}/>
                        </Col>
                    )}
                </div>
                <hr/>
                <h5 className="mb-3">Page Background</h5>
                <div className="row lp-step-14">
                    <Col md={6} xs={12}>
                        <Form.Group className="col-md-12 form-group">
                            <Form.Label htmlFor="status" style={{marginRight: '30px'}}>
                                Type of Background:
                            </Form.Label>
                            <Form.Check className="form-check-inline">
                                <FormCheck.Input
                                    type="radio"
                                    name="background"
                                    id="type-image"
                                    value="image"
                                    checked={values.background === 'image'}
                                    onChange={onChange}
                                />
                                <FormCheck.Label htmlFor="type-image" className="pl-2">
                                    Image
                                </FormCheck.Label>
                            </Form.Check>
                            <Form.Check className="form-check-inline">
                                <FormCheck.Input
                                    type="radio"
                                    name="background"
                                    id="type-video"
                                    value="video"
                                    checked={values.background === 'video'}
                                    onChange={onChange}
                                />
                                <FormCheck.Label htmlFor="type-video" className="pl-2">
                                    Video
                                </FormCheck.Label>
                            </Form.Check>
                        </Form.Group>
                    </Col>
                    {values.background === 'image' ? (
                        <Fragment>
                            <Col md={data?.backgroundImage ? 4 : 6} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label className="custom-file-input">Update Background Image</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".jpg,.gif,.png"
                                        id="background-image"
                                        name="backgroundImage"
                                        onChange={onFileChange}
                                    />
                                </Form.Group>
                            </Col>
                            {data?.backgroundImage && (
                                <Col xs={2} className="landing-page-image-container">
                                    <Image src={process.env.REACT_APP_S3_BUCKET_URL + data.backgroundImage}/>
                                </Col>
                            )}
                        </Fragment>
                    ) : (
                        <Fragment>
                            <Col md={data?.backgroundVideo ? 4 : 6} xs={12}>
                                <Form.Group className="form-group">
                                    <Form.Label className="custom-file-input">Update Background Video</Form.Label>
                                    <Form.Control
                                        type="file"
                                        accept=".mp4,.mov,.wmv"
                                        id="background-video"
                                        name="backgroundVideo"
                                        onChange={onFileChange}
                                    />
                                </Form.Group>
                            </Col>
                            {data?.backgroundVideo && (
                                <Col md={2} xs={12} className="landing-page-video-container">
                                    <video src={process.env.REACT_APP_S3_BUCKET_URL + data.backgroundVideo} controls/>
                                </Col>
                            )}
                        </Fragment>
                    )}
                </div>
                <hr/>
                <h5 className="mb-3">Status</h5>
                <Form.Group className="col-md-12 form-group lp-step-15">
                    <Form.Label htmlFor="status" style={{marginRight: '30px'}}>
                        Landing Page Status:
                    </Form.Label>
                    <Form.Check className="form-check-inline">
                        <FormCheck.Input
                            type="radio"
                            name="status"
                            id="statusActive"
                            value="ACTIVE"
                            checked={values.status === 'ACTIVE'}
                            onChange={onChange}
                        />
                        <FormCheck.Label htmlFor="statusActive" className="pl-2">
                            Active
                        </FormCheck.Label>
                    </Form.Check>
                    <Form.Check className="form-check-inline">
                        <FormCheck.Input
                            type="radio"
                            name="status"
                            id="statusInactive"
                            value="INACTIVE"
                            checked={values.status === 'INACTIVE'}
                            onChange={onChange}
                        />
                        <FormCheck.Label htmlFor="statusInactive" className="pl-2">
                            Inactive
                        </FormCheck.Label>
                    </Form.Check>
                </Form.Group>
                <hr/>
                <h5 className="mb-3">Terms and Conditions</h5>
                <Form.Check className="form-check-inline lp-step-16">
                    <FormCheck.Input
                        type="checkbox"
                        className="form-check-input"
                        id="enableTermsAndConditions"
                        checked={addTermsAndConditions}
                        onChange={toggleTermsAndConditions}
                    />
                    <FormCheck.Label className="form-check-label pl-2" htmlFor="enableTermsAndConditions">
                        Add Terms and Conditions
                    </FormCheck.Label>
                </Form.Check>
                {addTermsAndConditions && (
                    <LandingPageTextField name="termsAndConditions" label="" onChange={onChange} values={values}/>
                )}
                <div className="pb-3 pt-3">
                    <ShowErrors errors={errors}/>
                </div>
                <Button
                    type="button"
                    variant="btn btn-primary"
                    onClick={onSubmit}
                    disabled={mutation.loading}
                    className="lp-step-17"
                >
                    {mutation.loading ? (
                        <Fragment>
                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true"/>
                            {' ' + saveButtonName[1]}...
                        </Fragment>
                    ) : (
                        <Fragment>{saveButtonName[0]}</Fragment>
                    )}
                </Button>{' '}
                <Button
                    type="button"
                    variant="btn btn-danger"
                    onClick={() => {
                        navigate('/campaigns/' + campaignId + '/landing-pages/')
                    }}
                    disabled={mutation.loading}
                >
                    Cancel
                </Button>
            </Form>
        </Fragment>
    )
}

export default LandingPageForm

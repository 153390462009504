import React, { useReducer, createContext } from 'react'

const initialState = {
    state: null,
    platform: null,
    campaignId: null,
    exp: null
}

if (localStorage.getItem('oauth2')) {
    const oauth = localStorage.getItem('oauth2')

    if (oauth) {
        const decoded = JSON.parse(oauth)

        if (decoded.exp < Date.now()) {
            localStorage.removeItem('oauth2')
        } else {
            initialState.state = decoded.state
            initialState.platform = decoded.platform
            initialState.campaignId = decoded.campaignId
            initialState.exp = decoded.exp
        }
    }
}

const OAuth2Context = createContext({
    state: null,
    platform: null,
    campaignId: null,
    exp: null,
    setState: (state) => {},
    removeState: () => {}
})

const oauthReducer = (state, action) => {
    switch (action.type) {
        case 'SET':
            const data = JSON.parse(action.payload)
            return {
                ...state,
                state: data.state,
                platform: data.platform,
                campaignId: data.campaignId,
                exp: data.exp
            }
        case 'REMOVE':
            return {
                ...state,
                state: null,
                platform: null,
                campaignId: null,
                exp: null
            }
        default:
            return state
    }
}

const OAuth2Provider = (props) => {
    const [state, dispatch] = useReducer(oauthReducer, initialState)

    const setState = (data) => {
        data = JSON.stringify({
            ...data,
            exp: Date.now() + 600000
        })
        localStorage.setItem('oauth2', data)
        dispatch({
            type: 'SET',
            payload: data
        })
    }

    const removeState = () => {
        localStorage.removeItem('oauth2')
        dispatch({ type: 'REMOVE' })
    }

    return (
        <OAuth2Context.Provider
            value={{
                state: state.state,
                platform: state.platform,
                campaignId: state.campaignId,
                setState,
                removeState }}
            { ...props }
        />
    )
}

export { OAuth2Context, OAuth2Provider }
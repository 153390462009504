import React, { memo, useState, Fragment } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client'
import { LANDING_PAGE_FIELDS } from '../../graphql/landing-page-fragment'
import Card from '../../components/common/Card'
import Loader from '../../layouts/components/Loader'
import LandingPageForm from '../../components/campaigns/LandingPageForm'
import _ from 'lodash'

const GET_LANDING_PAGE = gql`
    ${LANDING_PAGE_FIELDS}
    query LandingPages($where: LandingPageWhere) {
        landingPages(where: $where) {
            ...LandingPageFields
            campaign {
                id
            }
        }
    }
`

const UPDATE_LANDING_PAGE = gql`
    ${LANDING_PAGE_FIELDS}
    mutation UpdateLandingPage($landingPageId: ID!, $landingPageInput: LandingPageInput) {
        updateLandingPage(landingPageId: $landingPageId, landingPageInput: $landingPageInput) {
            ...LandingPageFields
        }
    }
`

const GET_THEMES = gql`
    query Themes($options: ThemeOptions) {
        themes(options: $options) {
            id
            name
        }
    }
`

const EditLandingPage = memo(() => {
    const params = useParams()
    const landingPageId = params.id
    const [updatedData, setUpdatedData] = useState({})
    const [errors, setError] = useState([])
    const navigate = useNavigate()

    const { data, loading } = useQuery(GET_LANDING_PAGE, {
        variables: { where: { id: landingPageId } },
    })

    const themesQuery = useQuery(GET_THEMES, {
        variables: {
            options: { sort: [{ order: 'ASC' }] },
        },
    })

    const updateLandingPageCallback = async () => {
        await updateLandingPage()
    }

    const updateData = (data) => {
        setUpdatedData(data)
    }

    const setFormFieldsFromFormData = (formData) => {
        const formFields = []

        _.each(_.range(1, 9), (number) => {
            const label = formData[`field${number}Label`]
            if (label && label.length > 0) {
                formFields.push({
                    label,
                    required: formData[`field${number}Required`],
                    type: formData[`field${number}Type`],
                    enabled: formData[`field${number}Enabled`],
                    values: formData[`field${number}Values`],
                })
            }
        })

        return JSON.stringify(formFields)
    }

    const setButtonSettingsFromFormData = (formData) => {
        const { buttonLabel, buttonColour } = formData
        return JSON.stringify({ buttonLabel, buttonColour })
    }

    const setPageSettingsFromFormData = (formData) => {
        const {
            pageHeading,
            pageHeadingColor,
            pageHeadingFont,
            pageSubHeading,
            pageSubHeadingColor,
            pageSubHeadingFont,
        } = formData
        return JSON.stringify({
            pageHeading,
            pageHeadingColor,
            pageHeadingFont,
            pageSubHeading,
            pageSubHeadingColor,
            pageSubHeadingFont,
        })
    }

    const setPagePopupSettingsFromFormData = (formData) => {
        const { popupTitle, popupDescription, popupButtonLabel, popupButtonUrl, popupButtonColour } = formData
        return JSON.stringify({ popupTitle, popupDescription, popupButtonLabel, popupButtonUrl, popupButtonColour })
    }

    const [updateLandingPage, mutation] = useMutation(UPDATE_LANDING_PAGE, {
        onError({ graphQLErrors }) {
            setError(graphQLErrors)
        },
        onCompleted: () => {
            navigate(`/campaigns/${data.landingPages[0]?.campaign.id}/landing-pages/`, {
                state: {
                    notification: {
                        status: 'success',
                        message: 'Landing Page has been updated successfully!',
                    },
                },
            })
        },
        variables: {
            landingPageId: landingPageId,
            landingPageInput: {
                themeId: updatedData.theme,
                title: updatedData.title,
                domain: updatedData.domain,
                email: updatedData.email,
                status: updatedData.status,
                formFields: setFormFieldsFromFormData(updatedData),
                metaKeywords: updatedData.metaKeywords,
                metaDescription: updatedData.metaDescription,
                customHtmlHeader: updatedData.customHtmlHeader,
                hasForm: updatedData.hasForm,
                formButtonSettings: setButtonSettingsFromFormData(updatedData),
                pageSettings: setPageSettingsFromFormData(updatedData),
                pagePopupSettings: setPagePopupSettingsFromFormData(updatedData),
                formContent: updatedData.formContent,
                logo: updatedData.logo,
                logoImage: updatedData.logoImage !== '' ? updatedData.logoImage : null,
                background: updatedData.background ? updatedData.background : 'image',
                backgroundImage: updatedData.backgroundImage !== '' ? updatedData.backgroundImage : null,
                backgroundVideo: updatedData.backgroundVideo !== '' ? updatedData.backgroundVideo : null,
                termsAndConditions: updatedData.termsAndConditions,
            },
        },
    })

    return (
        <Fragment>
            {loading || themesQuery.loading ? (
                <Loader />
            ) : (
                <Card>
                    <Card.Header className="d-flex justify-content-between">
                        <div className="header-title">
                            <h4 className="card-title">
                                <i className="icon">
                                    <svg
                                        className="icon-25"
                                        width="25"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            opacity="0.4"
                                            d="M19.9926 18.9532H14.2983C13.7427 18.9532 13.2909 19.4123 13.2909 19.9766C13.2909 20.5421 13.7427 21 14.2983 21H19.9926C20.5481 21 21 20.5421 21 19.9766C21 19.4123 20.5481 18.9532 19.9926 18.9532Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M10.309 6.90388L15.7049 11.264C15.835 11.3682 15.8573 11.5596 15.7557 11.6929L9.35874 20.0282C8.95662 20.5431 8.36402 20.8345 7.72908 20.8452L4.23696 20.8882C4.05071 20.8904 3.88775 20.7614 3.84542 20.5765L3.05175 17.1258C2.91419 16.4916 3.05175 15.8358 3.45388 15.3306L9.88256 6.95548C9.98627 6.82111 10.1778 6.79746 10.309 6.90388Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            opacity="0.4"
                                            d="M18.1206 8.66544L17.0804 9.96401C16.9756 10.0962 16.7872 10.1177 16.6571 10.0124C15.3925 8.98901 12.1544 6.36285 11.2559 5.63509C11.1247 5.52759 11.1067 5.33625 11.2125 5.20295L12.2157 3.95706C13.1258 2.78534 14.7131 2.67784 15.9936 3.69906L17.4645 4.87078C18.0677 5.34377 18.4698 5.96726 18.6074 6.62299C18.7661 7.3443 18.5968 8.0527 18.1206 8.66544Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </i>{' '}
                                Creative - {data.landingPages[0].title}
                            </h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="new-user-info">
                            <LandingPageForm
                                data={data.landingPages[0]}
                                updateData={updateData}
                                callback={updateLandingPageCallback}
                                errors={errors}
                                mutation={mutation}
                                themes={themesQuery}
                                campaignId={data.landingPages[0].campaign.id}
                            />
                        </div>
                    </Card.Body>
                </Card>
            )}
        </Fragment>
    )
})

export default EditLandingPage

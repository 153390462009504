import { Container, Row, Col, Card } from 'react-bootstrap'

const PrivacyPolicy = () => {
    return (
        <Container>
            <Row>
                <Col lg={12}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Privacy Policy</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>This Privacy Policy explains how we will process the data relating to you, including the personal
                                data that you provide to us or that we may collect when you visit our website at <a href="https://turbamedia.io">www.turbamedia.io</a> (Website), and applies to all use of data by Turba Media PTY LTD
                                The Website is operated by Turba Media PTY LTD (“Turba Media”, “Turba”,“we”, “us” and “our”), a

                                company incorporated in AU & USA

                                Phone – 1 300 926 895
                                Email – info@turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <p>Turba Media Pty Ltd provides bespoke marketing services to customers to help them identify and
                                improve their understanding of their existing and prospective customers and target audiences, to
                                develop tailored marketing strategies and appropriate marketing content.
                                Where we decide the purpose and means for the processing of the personal data that you provide
                                to us, including via our Website, or that we obtain about you from others, we are the “data
                                controller,” such as in relation to Website Data and setting up a Turba customer account. Where we
                                process personal data in accordance with the specifications and instructions that we receive from
                                our customers, in particular in relation to Social Media and Customer Data, it is our customers who
                                decide the purpose and means for the processing of this personal data. In those instances, it is our
                                customers who are the data controller and we are the data processor. Whether we are acting in our
                                capacity as data controllers or as data processor, we will comply with all applicable data protection
                                laws..
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <p>This policy should be read together with our Website Terms of Use.
                                Our Website may contain hyperlinks to third-party websites. These websites operate fully
                                independently from us, and we cannot accept any responsibility or liability for the privacy practices
                                of such third parties or the availability of these external sites or resources. The appearance of
                                such links on our Website is not an endorsement. Should you use any of these websites, such use
                                is at your own risk and we would advise that you review their respective privacy policies.
                                Please read this Privacy Policy carefully as it contains important information about the following:
                                What information Turba media may collect about you
                                How Turba Media will use the information we collect about you
                                Whether Turba Media will disclose your details to anyone else
                                Your choices and rights regarding the personal information we hold about you
                                If you have any questions or comments about this Privacy Policy, please contact Turba media:

                                Phone – 1 300 926 895
                                By email: info@turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">1. WHAT PERSONAL INFORMATION DO WE COLLECT ABOUT YOU?</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>When you visit our Website, we may collect and process information (which may include your
                                personal information) for the purposes of processing and on the legal bases listed below, and we
                                may sometimes share this information as follows.
                                <li>General Enquires and visiting our Website (Website Data)</li>
                                <li>Types of DataProcessing Purpose and BasisThird Party Recipients</li>
                                We may collect and store personal information you provide when using the Website, including
                                when you log in to access your account or contact us with general enquiries or about job
                                openings displayed on our “Join Us” webpage by emailing us at info@turbamedia.io</p>

                                <p>Personal data that we may obtain includes:
                                    <li>your name</li>
                                    <li>your email address and/or postal address</li>
                                    <li>your company name</li>
                                    <li>your username (typically your email address) and password</li>
                                    <li>online identifiers, such as IP address, cookies and tracking data and other unique user identifiers</li>
                                    <li>your data held by social media, such as Google (where you opt to log in via Google)</li>
                                    <li>any other personal information you provide in a message you submit to us (including your CV and</li>
                                    <li>personal statement, where applying for jobs)</li>
                                </p>
                                <p>We may legally process the personal information that we collect on the basis that we have a
                                legitimate interest, under our legal obligations and/or in the performance of a contract:
                                    <li>to register and administer your user account</li>
                                    <li>(in relation to company accounts) to analyse your company budget and/or capacity to purchase our
                                products or services, including tracking whether your company receives investment or raises large
                                        amounts of capital</li>
                                    <li>to respond to your enquiries</li>
                                    <li>to provide you with information about the bespoke marketing services that we offer to customers to
                                        identify and improve understanding of their target audiences</li>
                                    <li>to respond when you submit job applications or related queries</li>
                                    <li>to manage risk or prevent other illegal or prohibited activities</li>
                                    <li>to resolve issues or fix problems on the Website.</li>
                                    </p>
                                <p>We keep your information confidential. We will not sell your information. We may disclose your
                                information to our personnel (including personnel in our group of companies) and to third-party
                                suppliers or subcontractors (including our independent consultants, data analytics providers and
                                cloud-based data processing and hosting providers).</p>
                                <p>If we do share your information, we will only do so insofar as it is reasonably necessary for the
                                purposes set out in this Privacy Policy, and provided that the recipients do not make independent
                                use of the information and have agreed to adhere to the rules set out in this Privacy Policy.
                                In some instances, this data sharing may involve the transfer of information outside the AU & USA.
                                Please see our section on International Data Transfers below.</p>
                                <p>As part of the service we provide, we use YouTube API under YouTube Terms of Service
                                https://www.youtube.com/t/terms (ToS) in API Client’s ToS and Google Privacy Policy
                                https://policies.google.com/privacy As part of those, Turba users will be agreeing with the YouTube
                                ToS.</p>
                                <p>Setting Up Your Account</p>
                                <p>Types of Data Processing Purpose and BasisThird Party Recipients</p>
                                <p>When you set up your Turba customer account, we will ask you to provide the following personal
                                information, depending on whether you register as a company or sole trader/individual.
                                All account holders may be asked to provide:
                            <li>full name</li>
                            <li>email address, postal address, telephone number(s)</li>
                            <li>your username and password (which you create)</li>
                            </p>
                            <p>To create an account and use our products and services, you may be asked to provide us
                                and/or our third-party service providers:
                                <li>photographic proof of identity (e.g. passport or driving licence)</li>
                                <li>proof of authority to act on behalf of your company</li>
                                <li>access to data (including personal data) in your customer database</li></p>
                                <p>To enable authorised users, such as employees and/or consultants of the account holder, to access
                                the account and our platform, we may require those end users to provide:
                                    <li>full name</li>
                                    <li>company name and job title</li>
                                    <li>email address, postal address, telephone number(s)</li>
                                    <li>your username and password (which you create)</li></p>
                                <p>We can only provide our services, including account set-up, where you have provided certain
                                requisite personal data.
                                We will process this information based on either our legal obligations, for the performance of a
                                contract or where we have a legitimate interest to do so in the operation of our bespoke marketing
                                services business, in order to:
                                    <li>create and maintain your account with us</li>
                                    <li>perform our contract for the provision of targeted marketing analysis and strategy development
                                        services</li>
                                    <li>provide authorised access and use of the consumer insight tool and platform, and to communicate
                                        with those users</li>
                                    <li>conduct identify and credit reference checks and for credit score reporting</li>
                                    <li>manage risk or prevent other illegal or prohibited activities, including in relation to anti-money
                                        laundering and prevention of fraud</li>
                                    <li>respond to your enquiries and provide assistance</li></p>
                                <p>We may share the personal information that you provide with third-party service providers, such as
                                external credit reference and reporting providers, to enable us to assess credit worthiness of
                                prospective customers, or other third-party sub-processors.
                                Where we share your personal data with third parties, we will only do so as far as it is reasonably
                                necessary for the purposes set out in the policy, provided that they do not make independent use
                                of the information and have agreed to adhere to the rules set out in the Privacy Policy.
                                In some instances, this sharing of data may involve the transfer of personal data outside of the AU
                                & USA. Please see our section on International Data Transfers below.</p>
                                <p>Social Media and Customer Data</p>
                                <p>Types of DataProcessing Purpose and BasisThird Party Recipients</p>
                                <p>In order to provide our customers with our business’ core service of bespoke, targeted marketing
                                analysis and strategy development, which we provide in accordance with our customer’s identified
                                needs and to their specification, we will need information about our customer’s existing and
                                prospective customers. To fulfil our customer’s instructions, we will process personal data as part
                                of the service we provide to help them identify, understand and more effectively reach their own
                                customers or intended audience.</p>

                                <p>In doing so, we may retrieve publicly available personal and non-personal information about
                                individuals that have been made available to social media platforms such as Facebook YouTube,
                                Instagram, Twitter and LinkedIn. This may include:
                                    <li>full name</li>
                                    <li>your posted comments (which may contain personal data, such as your preferences and opinions)
                                        user IDs</li>
                                    <li>location and device data</li></p>
                                <p>To provide our customers with analysis in line with their specifications and instructions, we may
                                collect and process your social media data and data provided by our customers (Customer Data)
                                to enable us to provide our core business services, including bespoke targeted marketing analysis
                                and advisory services, which may include:
                                    <li>to conduct analytics on visits to our Website, websites of our customers, and social media
                                        platforms (such as those administered by our customers and generally)</li>
                                    <li>to build aggregated and anonymised group profiles with respect of a particular customer’s target
                                        markets to analyse user trends and preferences to advise on and devise appropriate marketing</li>
                                    <li>content for customers to understand which potential new features, advertising or campaigns are
                                likely to interest our customers and/or their customers and targeted audiences to create a
                                database to assist with analysis and provision of advice on appropriate marketing content,
                                including (where applicable) the attribution of social media accounts to audience segments to
                                improve the quality and design of our services. We minimise the collection of information to the
                                minimum required to enable us to perform under our contracts for the provision of services with our
                                customers, including to provide and operate customer accounts, to fulfil legal obligations, and/or
                                        where there are other lawful bases for processing.</li></p>

                                <p>The information obtained, as set out in this section, may be used in order to enable the remarking
                                of users and may be shared with Google. We will not provide further personal information to these
                                payment service providers beyond the financial details that you have provided.
                                As part of the service that we provide, including the maintenance and operation of your account
                                and our Open Banking services, we will receive and hold financial information that you may provide
                                directly to our third-party service providers. We will only provide such information to other third
                                parties where we have received your prior consent to do so or where we are legally obliged to do
                                so.</p>
                                <p>As part of the service we provide, we use YouTube API under YouTube Terms of Service
                                https://www.youtube.com/t/terms (ToS) in API Client’s ToS and Google Privacy Policy
                                https://policies.google.com/privacy As part of those, Turba users will be agreeing with the YouTube
                                ToS.</p>
                                <p>Social Media and Customer Data</p>
                                <p>Types of DataProcessing Purpose and BasisThird Party Recipients
                                Our Website does not contain third-party advertising.</p>
                                <p>We may collect information about you in order to send you our promotional or marketing
                                communications, including our blogs and updates, such as:
                                    <li>your name</li>
                                    <li>your email address</li>
                                    <li>your company name</li>
                                    <li>your job title</li></p>

                                <p>While we do not permit third-party advertising on our Website, if you do link to one of our Turba or
                                Turba Media Pty Ltd social media platforms you may be subject to targeted marketing via that site.
                                Where we provide you with information about our products, services or offers via our email
                                updates, blogs or e-newsletters, we will only use your personal information in this way in
                                accordance with laws relating to marketing directly to individuals, and in the pursuit of our
                                legitimate interest of marketing our business.
                                If you have asked to be added to our mailing list, such as by submitting your details via our
                                Website sign-up, we will provide your details to a third-party company that helps us to produce and
                                manage the circulation of our blogs and newsletters. We provide these communications on the
                                basis that you have asked to receive these.
                                If you change your mind, you may opt out at any time via the unsubscribe feature that appears in
                                our emails or by emailing info@turbamedia.io
                                You may also be asked to opt in to receive updates or information about similar services from our
                                network of trusted third parties or to receive promotional material, including vouchers or discount
                                offers, from these suppliers.</p>
                                <p>We will not share with any third party the personal information that we obtain about you for the
                                purposes of marketing, except for as stated in this policy and only where those third parties have
                                agreed to make no independent or further use of that data and to maintain its confidentiality.</p>
                                <p>Our Approach to Objectionable Content</p>
                                <p>If you send us objectionable content, or behave in a disruptive manner when using our website,
                                we may process any personal information that you have submitted to us, including your personal
                                messages, to respond to and stop such behaviour.</p>
                                <p>We will only process personal information in this way for the following legitimate interests:
                                    <li>ensuring that the use of our Website is lawful;</li>
                                    <li>ensuring that users do not disrupt or harass our staff, customers or other Website users; and
                                        enforcing our legal rights and complying with our legal obligations.</li></p>
                                <p>Where we reasonably believe that you are or may be in breach of the law (i.e. the content you
                                share on the Website amounts to harassment or is defamatory), we may use your personal
                                information to inform relevant third parties about the content, such as:
                                    <li>your email or internet provider; or</li>
                                    <li>law enforcement agencies</li></p>
                                <p>When we process personal information in this way, we will hold that personal information on our
                                    systems for as long as is reasonably necessary to achieve these objectives.</p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">2. DATA SHARING WITH OTHER PARTIES</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>We will only ever share your information with third parties in the ways that are described in this
                                Privacy Policy. If you would like to find out more about how those third parties listed in this Privacy
                                Policy use your information, this should be set out in their respective privacy policies accessible on
                                their websites.
                                Personnel, Suppliers and Subcontractors

                                We keep your information confidential but may disclose it to any member of our group (which
                                means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section
                                1159 of the Companies Act 2006), our personnel or independent contractors and our suppliers or
                                service providers insofar as it is reasonably necessary for the purposes set out in this Privacy
                                Policy. However, this is on the basis that they do not make independent use of the information, and
                                have agreed to safeguard this information. For further detail of third-party service providers
                                that we engage, please see below.
                                Change of Control
                                If we are involved in a merger, acquisition, or sale of all or a portion of our business or its assets,
                                you will be notified of any change in ownership or uses of this information, as well as any choices
                                you may have regarding this information.
                                Disclosure Required by Law or for Protection of the Business
                                In addition, we may disclose your information to the extent that we are required to do so by law
                                (which may include to government bodies and law enforcement agencies), in connection with any
                                legal proceedings or prospective legal proceedings; and in order to establish, exercise or defend
                                our legal rights (including providing information to others for fraud prevention).
                                We may also disclose your personal information to third parties in order to enforce or apply the
                                terms of agreements between us, to investigate potential breaches, or to protect the rights,
                                property or safety of Turba Media, our customers, or others. This includes exchanging information
                                with other companies and organisations for the purposes of fraud protection and credit risk
                                reduction.
                                Third-party service providers
                                In addition to the processing that we undertake ourselves, we also use third-party processors to
                                collect, export, store and otherwise process our data, including personal data, on our behalf. The
                                processors that we engage include (without limitation):
                                Facebook, Inc. (including Instagram) (Social Media Platform)
                                Location: US (AU & USA-US Privacy Shield certified)
                                https://www.facebook.com/privacy/policy
                                Twitter (Social Media Platform)
                                Location: Ireland (for non-US customers)
                                https://twitter.com/en/privacy
                                LinkedIn (Social Media Platform)
                                Location: Various, including UK and US
                                https://www.linkedin.com/legal/privacy-policy
                                Intercom, Inc. (Digital Marketing / Customer Messaging Platform)
                                Location:www.intercom.com
                                https://www.intercom.com/terms-and-policies#privacy
                                HubSpot, Inc.
                                Location:https://www.hubspot.com/
                                https://legal.hubspot.com/privacy-policy
                                Google LLC (for Google Analytics and Google Cloud) (Web Management Tools)
                                Location: US (AU & USA-US Privacy Shield certified)
                                https://policies.google.com/privacy?hl=en
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">3. COOKIES & ANALYTICS</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>Our Website uses cookies and other mechanisms to collect log and analytical information, to help
                                analyse how visitors use the site, to personalise content, support our security function and to
                                compile statistical reports on Website activity.
                                Cookies may be used by us to do the following:
                                enable us to respond to you and to tailor our operations to your needs, including remembering your
                                preferences and to assist with account or user security (such as sending reset password
                                reminders)
                                assist us in the continued improvement and effective operation of the Website
                                enable Website traffic to be logged to identify the web pages you visit and use so we may better
                                understand browsing behaviour (including which web pages browsers find more useful) and
                                improve our Website
                                To find out more about the use of cookies generally, please visit http://www.allaboutcookies.org/.
                                Our Website also uses website analytics, including Google Analytics as well as our own analytics
                                software, to evaluate and improve our Website, personalise your experience and to offer the best,
                                most accessible service possible to all of our customers.
                                To find out more about Google Analytics and to learn how to opt-out, please visit:
                                How Google uses data when you use our partners&#39; sites or apps, or
                                https://tools.google.com/dlpage/gaoptout/.
                                When you visit our Website, these analytics service providers may collect the following data, which
                                will almost always be anonymised and aggregated before reporting back to us:
                                number of visitors to our Website
                                pages visited while at the Website and time spent per page
                                page interaction information, such as scrolling, clicks and browsing methods
                                websites where visitors have come from and where they go afterwards
                                page response times and any download errors
                                date and time of the visit
                                other technical information relating to end-user device, such as IP address, access status/HTTP
                                status code, your operating system and interface or browser plug-in
                                We process this information to understand how visitors use our Website and to compile statistical
                                reports regarding that activity (for example, your IP address is used to approximate the geographic
                                locations (e.g. country, region, city) from which you access our Website, and we aggregate this
                                information together so we know, for example, whether most of the visitors to our Website are
                                Melbourne-based, originate from the AU or come from elsewhere).
                                This processing is crucial to the running of our online business and we therefore undertake such
                                monitoring in the pursuit of our legitimate interests in improving our Website to provide a better
                                service and source of information to visitors and our customers.
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">4. YOUR RIGHTS IN RELATION TO THE PERSONAL DATA THAT WE PROCESS</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>You have the following rights over the way we process personal data relating to you. We aim to
                                comply without undue delay and within one month at the latest:
                                to ask for confirmation and information about the personal data that we process about you,
                                including a copy of data we are processing about you;
                                to have inaccuracies corrected or incomplete personal data made complete;

                                to ask us to restrict, stop processing, or to delete your personal data;
                                to request a machine-readable copy of your personal data, which you can use with another service
                                provider. Where it is technically feasible, you can ask us to send this information directly to another
                                provider if you prefer; and
                                to not be subject to automated decision-making, including profiling; and
                                to make a complaint to a data protection regulator.
                                To make a request in relation to any of the aforementioned rights, please email us at
                                info@Turbamedia.io.
                                If you are unhappy with the way that we are processing your personal data, please let us know.
                                The best way to bring this to our attention is by emailing us at info@Turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">5. CHILDREN</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>We do not knowingly use the Website or our services to solicit data from or market to anyone
                                under the age of 18 (including, in particular, children under the age of 13) nor do we sell any of our
                                products or services to children.
                                If a parent or guardian becomes aware that his or her child has provided us with information or
                                may be receiving communications from us or has been otherwise interacting with us via our
                                Website without consent of a parent or guardian, we ask that this be brought to our immediate
                                attention. We will make it our priority to address this situation and delete information relating to a
                                child as soon as practicable. In such an event, please contact us at info@Turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">6. SECURITY</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>We will take commercially reasonable, appropriate technical and organisational measures to
                                ensure a level of security appropriate to the risk that could be encountered via the use of our
                                Website and services, taking into account the likelihood and severity those risks might pose to the
                                rights and freedoms of our Website visitors.
                                In particular, we will take precautions to protect against the accidental or unlawful destruction, loss
                                or alteration, and unauthorised disclosure of or access to the personal information transmitted,
                                stored or otherwise processed by us.
                                Please be aware that, while we make the security of our Website and your personal information a
                                high priority and devote considerable time and resources to maintain robust IT security, no security
                                system can prevent all security breaches. When you choose to share your personal information
                                with us, you accept the aforesaid and provide your information at your own risk.
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">7. RETENTION</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>In accordance with data protection laws and good commercial practice, we do not retain data in a
                                form that permits identification of the person(s) to whom it relates for any longer than is necessary.
                                We only keep copies of all types of data for the purposes and duration of providing our services.
                                Once the purpose for which information has been collected has been fulfilled, we will either delete
                                your personal information or remove all identifiers within it so that it is no longer personal data. We
                                may use such anonymised data for research and/or business analysis purposes. For example:
                                Once our services have been completed and delivered to our customers, all Customer Data and
                                Social Media Data will be purged from our system.
                                Where you have provided us with personal information in order to set up an account with us, we
                                will retain those details for as long as your account remains active.
                                Where you have signed up to our mailing list, we will retain your details for as long as you remain
                                on that list. If you unsubscribe, we will remove your details from the list.

                                Where we obtain your personal data in relation to the use or purchase of our services, including
                                VAT or invoicing information, we are obligated by law to keep this for a minimum of 6 years.
                                All data that we receive from our customers is stored separately from data that we collect
                                ourselves. No customer data is stored by us after the services have been completed for the
                                relevant customers.
                                If you would like us to stop using your personal information or restrict its use, please email us at
                                info@Turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">8. INTERNATIONAL DATA TRANSFERS</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>Our servers are located in the AU & USA and the information that we collect directly from you will
                                be stored in these servers. We may also transfer your personal data to our third-party service
                                providers, many of whom may be located outside of the AU & USA, operate from multiple locations
                                including non-AU & USA based operations or engaging sub-processors located outside the AU &
                                USA.
                                There are agreements in place to ensure that any international transfers of personal data to our
                                affiliates or third-party service providers have appropriate safeguards that meet the requirements of
                                AU & USA data protection laws.
                                Such appropriate safeguards may include standard data protection clauses adopted by a data
                                protection regulator and approved by the AU & USA Commission, such as the AU & USA
                                Commission’s standard contractual clauses. Alternatively, where personal data is transferred to the
                                US, many of those US third-party service providers are certified under the AU & USA-US Privacy
                                Shield framework approved by the AU & USA Commission.

                                To find out more about the AU & USA-US Privacy Shield certification, please visit:
                                https://www.privacyshield.gov/Program-Overview
                                If you would like to find out more about these safeguards or our international transfer of data,
                                please let us know by emailing us at info@turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">9. CHANGES TO THIS POLICY</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>This Privacy Policy may be updated from time to time. We will notify you of any changes to this
                                policy by posting the updated policy on our Website. You are advised to consult this Privacy Policy
                                webpage regularly for any changes.
                            </p>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">10. CONTACT</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <p>Questions, comments and requests in relation to this Privacy Policy are welcome and should be
                                addressed to Turba Media Pty Ltd,
                                2023 © TurbaPrivacy statement
                                info@Turbamedia.io
                            </p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PrivacyPolicy
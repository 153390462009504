import validator from 'validator'

export const usePasswordValidation = (values, canBeEmpty = false) => {
    const passwordValidation = () => {
        const inputPassword = document.getElementById('password')
        const inputConfirmPassword = document.getElementById('confirmPassword')
        const emptyCondition = canBeEmpty ? values.password.length === 0 : false

        if (emptyCondition || validator.isStrongPassword(values.password, { minLength: 6, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1})) {
            inputPassword.setCustomValidity('')
        } else {
            inputPassword.setCustomValidity('Weak Password')
        }

        if (values.password === values.confirmPassword) {
            inputConfirmPassword.setCustomValidity('')
        } else {
            inputConfirmPassword.setCustomValidity('Passwords do not match')
        }
    }

    return { passwordValidation }
}
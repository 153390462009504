import React, { Fragment, memo, useContext, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SettingsContext } from '../context/settings-context'
import { useForm } from '../utils/form-hook'
import { useDotsAnimation } from '../utils/dot-animation-hook'
import { usePasswordValidation } from '../utils/password-validation-hook'
import { gql, useMutation } from '@apollo/client'
import { Row, Col, Form, Alert, Spinner, Button } from 'react-bootstrap'
import Card from '../components/common/Card'
import LogoDark from '../assets/images/logo-text-dark.png'
import LogoLight from '../assets/images/logo-text-light.png'

const REGISTER_USER = gql`
    mutation Mutation($signUpInput: SignUpInput, $captchaToken: String!) {
        signUp(signUpInput: $signUpInput, captchaToken: $captchaToken)
    }
`

const SignUp = memo(() => {
    const { theme } = useContext(SettingsContext)
    const navigate = useNavigate()

    const [errors, setError] = useState([])
    const [message, setMessage] = useState('')

    const registerUserCallback = async () => {
        try {
            const token = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY, {
                action: 'signup',
            })
            await registerUser({
                variables: { signUpInput: values, captchaToken: token },
            })
        } catch (error) {
            setError([{ message: 'reCAPTCHA verification failed. Please try again.' }])
        }
    }

    const { onChange, onSubmit, values, validated, validateEmail } = useForm(registerUserCallback, {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        confirmPassword: '',
    })

    const { passwordValidation } = usePasswordValidation(values)
    const { animationRef } = useDotsAnimation()

    const [registerUser, { loading }] = useMutation(REGISTER_USER, {
        update(proxy, { data: { signUp: successMessage } }) {
            setMessage(successMessage)
        },
        onError({ graphQLErrors }) {
            setError(graphQLErrors)
        },
    })

    const openLink = (url) => {
        window.open(url, '_blank', 'noreferrer')
    }

    return (
        <Fragment>
            <section className="iq-auth-page" ref={animationRef}>
                <Row className="d-flex align-items-center justify-content-center vh-100 w-100">
                    <Col md="4" xl="4">
                        <Card className="p-4">
                            <Card.Body>
                                <div className="text-center" style={{ marginBottom: '30px' }}>
                                    <img
                                        src={theme === 'dark' ? LogoDark : LogoLight}
                                        alt="Turba Media"
                                        style={{ height: '45px' }}
                                    />
                                </div>
                                <h3 className="text-center">Sign Up</h3>
                                <p className="text-center">Create your Turba account</p>
                                {message && (
                                    <>
                                        <Alert variant="success">{message}</Alert>
                                        <div className="text-center pb-3">
                                            <Button
                                                type="button"
                                                variant="btn btn-primary"
                                                onClick={() => navigate('/login')}
                                            >
                                                Go to Login
                                            </Button>
                                        </div>
                                    </>
                                )}
                                {message === '' && (
                                    <>
                                        <Form noValidate validated={validated}>
                                            <Row className="d-flex justify-content-between">
                                                <Form.Group className="form-group col-6">
                                                    <Form.Label className="form-label">First Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className="mb-0"
                                                        placeholder="Enter First Name"
                                                        name="firstName"
                                                        onChange={onChange}
                                                    ></Form.Control>
                                                </Form.Group>
                                                <Form.Group className="form-group col-6">
                                                    <Form.Label>Last Name</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className="mb-0"
                                                        placeholder="Enter Last Name"
                                                        name="lastName"
                                                        onChange={onChange}
                                                    ></Form.Control>
                                                </Form.Group>
                                            </Row>
                                            <Row className="d-flex justify-content-between">
                                                <Form.Group className="form-group col-12">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="email"
                                                        className="mb-0"
                                                        placeholder="Enter Email"
                                                        name="email"
                                                        id="email"
                                                        onChange={onChange}
                                                        onKeyUp={validateEmail}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {values.email.length > 0 ? 'Please enter a valid Email' : ''}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <Row className="d-flex justify-content-between">
                                                <Form.Group className="form-group col-6">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        className="mb-0"
                                                        placeholder="Enter Password"
                                                        name="password"
                                                        id="password"
                                                        onChange={onChange}
                                                        onKeyUp={passwordValidation}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {values.password.length > 0 ? 'Weak Password' : ''}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="form-group col-6 mb-3">
                                                    <Form.Label>Confirm Password</Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="password"
                                                        className="mb-0"
                                                        placeholder="Enter Confirm Password"
                                                        name="confirmPassword"
                                                        id="confirmPassword"
                                                        onChange={onChange}
                                                        onKeyUp={passwordValidation}
                                                    ></Form.Control>
                                                    <Form.Control.Feedback type="invalid">
                                                        {values.password !== values.confirmPassword
                                                            ? 'Passwords do not match'
                                                            : ''}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Row>
                                            <div className="pb-3">
                                                {errors.map((error, key) => {
                                                    return (
                                                        <Alert
                                                            key={key}
                                                            variant="warning rounded-0 alert-dismissible fade show"
                                                            role="alert"
                                                        >
                                                            <span> {error.message} </span>
                                                        </Alert>
                                                    )
                                                })}
                                            </div>
                                            <div className="text-center pb-3">
                                                <input
                                                    required
                                                    type="checkbox"
                                                    className="form-check-input me-1"
                                                    id="aggrement-hopeui"
                                                />
                                                <Form.Check.Label
                                                    className="form-check-label"
                                                    htmlFor="aggrement-hopeui"
                                                    role="button"
                                                    onClick={() => openLink('https://turbamedia.io/privacy-policy')}
                                                >
                                                    I agree with the terms of use
                                                </Form.Check.Label>
                                                <Form.Control.Feedback type="invalid">
                                                    You must agree before submitting.
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="text-center pb-3">
                                                <div className="d-flex justify-content-center">
                                                    <input
                                                        required
                                                        type="checkbox"
                                                        className="form-check-input me-1"
                                                        id="aggrement-google"
                                                        style={{ width: '2em' }}
                                                    />
                                                    <Form.Check.Label
                                                        className="form-check-label"
                                                        htmlFor="aggrement-google"
                                                        role="button"
                                                    >
                                                        I agree to the sharing of my data with third-party tools,
                                                        including AI models, as outlined in the app’s privacy policy.
                                                    </Form.Check.Label>
                                                </div>
                                                <Form.Control.Feedback type="invalid">
                                                    You must agree before submitting.
                                                </Form.Control.Feedback>
                                            </div>
                                            <div className="text-center pb-3">
                                                <Button
                                                    type="button"
                                                    variant="btn btn-primary"
                                                    onClick={onSubmit}
                                                    disabled={loading}
                                                >
                                                    {loading ? (
                                                        <Fragment>
                                                            <Spinner
                                                                as="span"
                                                                animation="grow"
                                                                size="sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />{' '}
                                                            Signing up...
                                                        </Fragment>
                                                    ) : (
                                                        <Fragment>Sign up</Fragment>
                                                    )}
                                                </Button>
                                            </div>
                                        </Form>
                                        <p className="text-center">Or sign in with other accounts?</p>
                                        <p className="text-center">
                                            Already have an Account <Link to="/login">Sign in</Link>
                                        </p>
                                    </>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Fragment>
    )
})

SignUp.displayName = 'SignUp'

export default SignUp
